<template>
  <page-layout>
    <template v-slot:content>

      <page-header>
        <template v-slot:icon>
          <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46"></path></svg>
        </template>
        <template v-slot:title>
          Bulletin Board
        </template>
      </page-header>

      <div class="my-5">
        <h4 class="mb-4 font-semibold mb-10">Important Installer news & information regarding the Intelli EV charger range.</h4>

        <div class="grid gap-4">
          <div class="p-4 rounded-lg bg-white shadow" :key="'news-' + n" v-for="(x, n) of getNews">
            <div class="flex my-2 w-full">
              <div class="flex-grow font-semibold text-lg">{{ x.title }}</div>
              <div class="text-sm text-gray-500">{{ x.date }}</div>
            </div>
            <div class="prose-sm my-4 w-full">
              <p :key="pi" v-for="(p, pi) of x.content">{{ p }}</p>
            </div>
          </div>
        </div>

      </div>

    </template>
  </page-layout>
</template>

<script>
import PageLayout from "@/Layout/PageLayout";
import PageHeader from "@/components/PageHeader";
import dayjs from "dayjs";
import {useMeta} from "vue-meta";

export default {
  name: "BulletinBoard",
  components: {PageHeader, PageLayout},
  setup(){
    useMeta({
      title: 'Bulletin Board'
    })
  },
  data(){
    return {
      items: [
        {title: 'Important Installer Process', date: '2023-04-18', content: [
            'Electrical devices should only be installed, operated, and maintained by qualified technicians.',
                'No responsibility is assumed by the manufacturer for any consequences arising out of the use of this device.',
          'A qualified technician is an electrician who has certified skills and knowledge related to the construction, installation, and operation of this type of electrical device and who has received safety training to recognize and avoid the hazards involved.',
              'All applicable local, regional, and national regulations must be applied when installing, repairing, and maintaining Intelli EV Chargers.',
          'The Charge Point is equipped with PEN Fault Protection and automatic disconnection system which satisfies the requirements of BS7671:2018 Amendment 2:2022 722.411.4.1 (iv) (the 18th Edition IET Wiring Regulations). This device provides protection of electric shock which electrically disconnects the vehicle from the live conductors of the supply and from protective earth in accordance with Regulation 543.3.3.101 (ii) within 5 s in the event of the utilization voltage at the charging point, between line and neutral conductors being greater than 253 V rms or less than 207 V rms.',
'This means the Charge Point can be installed without the need for an additional earth rod. However, if the customer or local regulations require that an earth rod is installed (for instance as part of a TT earthed system) then this should be connected to the dedicated terminal within the charger.'
          ]},
        {title: 'RCD/RCBO Leakage', date: '2023-04-11', content: [
            'We strongly recommend that all Intelli EV Charge Points are installed with an inline Type A RCD/RCBO for leakage protection.'
          ]}
      ]
    }
  },
  computed: {
      getNews(){
        return this.items.map((x) => {
          x.date = dayjs(x.date).format('DD MMMM YYYY')
          return x;
        })
      }
  }
}
</script>