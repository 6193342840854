<template>
  <page-layout>
    <template v-slot:content>

      <page-header>
        <template v-slot:icon>
          <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"></path></svg>
        </template>
        <template v-slot:title>
          Your Basket
        </template>
      </page-header>


      <div class="mt-10 bg-green-100 text-green-800 rounded shadow p-4 text-center" v-if="orderConfirmed">
        <p>Thank you for your order. Please check your inbox for details.</p>
      </div>

      <div class=" mt-10" v-if="basket.totalBasketItems === 0">
        <empty-state>
          <template v-slot:title>
            Your basket is currently empty.
          </template>
        </empty-state>
      </div>

      <div v-else class="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16 mt-10">
        <div>

          <div class="p-6" v-if="calculating_basket">
            <spin-loader />
          </div>
          <div v-else>
            <div>
              <h2 class="text-lg font-medium text-gray-900">Billing Information</h2>

              <div class="grid grid-cols-6 gap-6 mt-4">
                <div class="col-span-6 lg:col-span-3">
                  <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">First name<span
                      class="ml-1 text-red-700">*</span>
                  </label>
                  <input type="text"
                         :disabled="purchasing"
                         :class="{
                          'bg-gray-50 cursor-not-allowed': purchasing,
                          'border focus:ring-red-500 border-red-500': v$.form.billing_first_name.$errors.length > 0
                               }"
                         v-model="form.billing_first_name" name="first-name"
                         id="first-name" autocomplete="first-name"
                         class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                  <div class="mt-1 text-sm text-red-700" v-for="error of v$.form.billing_first_name.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </div>
                </div>

                <div class="col-span-6 lg:col-span-3">
                  <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Last name<span
                      class="ml-1 text-red-700">*</span>
                  </label>
                  <input type="text"
                         :disabled="purchasing"
                         :class="{
                          'bg-gray-50 cursor-not-allowed': purchasing,
                          'border focus:ring-red-500 border-red-500': v$.form.billing_last_name.$errors.length > 0
                               }"
                         v-model="form.billing_last_name" name="first-name"
                         id="first-name" autocomplete="first-name"
                         class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                  <div class="mt-1 text-sm text-red-700" v-for="error of v$.form.billing_last_name.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </div>
                </div>

                <div class="col-span-6">
                  <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Company name<span
                      class="ml-1 text-red-700">*</span>
                  </label>
                  <input type="text"
                         :disabled="purchasing"
                         :class="{
                          'bg-gray-50 cursor-not-allowed': purchasing,
                          'border focus:ring-red-500 border-red-500': v$.form.billing_company_name.$errors.length > 0
                               }"
                         v-model="form.billing_company_name" name="first-name"
                         id="first-name" autocomplete="first-name"
                         class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                  <div class="mt-1 text-sm text-red-700" v-for="error of v$.form.billing_company_name.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </div>
                </div>

                <div class="col-span-6">
                  <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Address line one<span
                      class="ml-1 text-red-700">*</span>
                  </label>
                  <input type="text"
                         :disabled="purchasing"
                         :class="{
                          'bg-gray-50 cursor-not-allowed': purchasing,
                          'border focus:ring-red-500 border-red-500': v$.form.billing_address_line_one.$errors.length > 0
                               }"
                         v-model="form.billing_address_line_one" name="first-name"
                         id="first-name" autocomplete="first-name"
                         class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                  <div class="mt-1 text-sm text-red-700" v-for="error of v$.form.billing_address_line_one.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </div>
                </div>

                <div class="col-span-6">
                  <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Address line two
                  </label>
                  <input type="text"
                         :disabled="purchasing"
                         :class="{
                          'bg-gray-50 cursor-not-allowed': purchasing,
                               }"
                         v-model="form.billing_address_line_two" name="first-name"
                         id="first-name" autocomplete="first-name"
                         class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                </div>

                <div class="col-span-6 lg:col-span-3">
                  <label for="town-city" class="block text-sm font-medium leading-6 text-gray-900">Town/City<span
                      class="ml-1 text-red-700">*</span>
                  </label>
                  <input type="text"
                         :disabled="purchasing"
                         :class="{
                          'bg-gray-50 cursor-not-allowed': purchasing,
                          'border focus:ring-red-500 border-red-500': v$.form.billing_town_city.$errors.length > 0
                               }"
                         v-model="form.billing_town_city" name="first-name"
                         id="first-name" autocomplete="first-name"
                         class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                  <div class="mt-1 text-sm text-red-700" v-for="error of v$.form.billing_town_city.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </div>
                </div>
                <div class="col-span-6 lg:col-span-3">
                  <label for="county-region" class="block text-sm font-medium leading-6 text-gray-900">County/Region
                    <span class="ml-1 text-red-700">*</span>
                  </label>
                  <input type="text"
                         :disabled="purchasing"
                         :class="{
                          'bg-gray-50 cursor-not-allowed': purchasing,
                          'border focus:ring-red-500 border-red-500': v$.form.billing_county_region.$errors.length > 0
                               }"
                         v-model="form.billing_county_region" name="first-name"
                         id="first-name" autocomplete="first-name"
                         class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                  <div class="mt-1 text-sm text-red-700" v-for="error of v$.form.billing_county_region.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </div>
                </div>
                <div class="col-span-6 lg:col-span-3">
                  <label for="postcode" class="block text-sm font-medium leading-6 text-gray-900">Postcode<span
                      class="ml-1 text-red-700">*</span>
                  </label>
                  <input type="text"
                         :disabled="purchasing"
                         :class="{
                          'bg-gray-50 cursor-not-allowed': purchasing,
                          'border focus:ring-red-500 border-red-500': v$.form.billing_postcode.$errors.length > 0
                               }"
                         v-model="form.billing_postcode" name="first-name"
                         id="first-name" autocomplete="first-name"
                         class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                  <div class="mt-1 text-sm text-red-700" v-for="error of v$.form.billing_postcode.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </div>
                </div>

              </div>
            </div>
            <div class="mt-10 border-t border-gray-200 pt-10">
              <h2 class="text-lg font-medium text-gray-900">Shipping Information</h2>

              <div class="relative flex items-start mt-6">
                <div class="flex h-6 items-center">
                  <input id="shipping-toggle"
                         v-model="shipping_address_same"
                         :disabled="purchasing"
                         :class="{
                           'bg-opacity-50 cursor-not-allowed': purchasing
                         }"
                         type="checkbox" class="h-6 w-6 rounded border-gray-300 text-intelli-green focus:ring-intelli-green">
                </div>
                <div class="ml-3 text-sm leading-6">
                  <label for="shipping-toggle"
                         :class="{
                  'cursor-not-allowed': purchasing
                }"
                         class="font-medium text-gray-900">Shipping address is same as billing address</label>
                </div>
              </div>

              <div class="grid grid-cols-6 gap-6 mt-4" v-if="!shipping_address_same">

                <div class="col-span-6 lg:col-span-3">
                  <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">First name<span
                      class="ml-1 text-red-700">*</span>
                  </label>
                  <input type="text"
                         :disabled="purchasing"
                         :class="{
                          'bg-gray-50 cursor-not-allowed': purchasing,
                          'border focus:ring-red-500 border-red-500': v$.form.shipping_first_name.$errors.length > 0
                               }"
                         v-model="form.shipping_first_name" name="first-name"
                         id="first-name" autocomplete="first-name"
                         class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                  <div class="mt-1 text-sm text-red-700" v-for="error of v$.form.shipping_first_name.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </div>
                </div>

                <div class="col-span-6 lg:col-span-3">
                  <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Last name<span
                      class="ml-1 text-red-700">*</span>
                  </label>
                  <input type="text"
                         :disabled="purchasing"
                         :class="{
                          'bg-gray-50 cursor-not-allowed': purchasing,
                          'border focus:ring-red-500 border-red-500': v$.form.shipping_last_name.$errors.length > 0
                               }"
                         v-model="form.shipping_last_name" name="first-name"
                         id="first-name" autocomplete="first-name"
                         class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                  <div class="mt-1 text-sm text-red-700" v-for="error of v$.form.shipping_last_name.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </div>
                </div>

                <div class="col-span-6">
                  <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Company name<span
                      class="ml-1 text-red-700">*</span>
                  </label>
                  <input type="text"
                         :disabled="purchasing"
                         :class="{
                          'bg-gray-50 cursor-not-allowed': purchasing,
                          'border focus:ring-red-500 border-red-500': v$.form.shipping_company_name.$errors.length > 0
                               }"
                         v-model="form.shipping_company_name" name="first-name"
                         id="first-name" autocomplete="first-name"
                         class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                  <div class="mt-1 text-sm text-red-700" v-for="error of v$.form.shipping_company_name.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </div>
                </div>

                <div class="col-span-6">
                  <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Address line one<span
                      class="ml-1 text-red-700">*</span>
                  </label>
                  <input type="text"
                         :disabled="purchasing"
                         :class="{
                          'bg-gray-50 cursor-not-allowed': purchasing,
                          'border focus:ring-red-500 border-red-500': v$.form.shipping_address_line_one.$errors.length > 0
                               }"
                         v-model="form.shipping_address_line_one" name="first-name"
                         id="first-name" autocomplete="first-name"
                         class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                  <div class="mt-1 text-sm text-red-700" v-for="error of v$.form.shipping_address_line_one.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </div>
                </div>

                <div class="col-span-6">
                  <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Address line two
                  </label>
                  <input type="text"
                         :disabled="purchasing"
                         :class="{
                          'bg-gray-50 cursor-not-allowed': purchasing,
                               }"
                         v-model="form.shipping_address_line_two" name="first-name"
                         id="first-name" autocomplete="first-name"
                         class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                </div>

                <div class="col-span-6 lg:col-span-3">
                  <label for="town-city" class="block text-sm font-medium leading-6 text-gray-900">Town/City<span
                      class="ml-1 text-red-700">*</span>
                  </label>
                  <input type="text"
                         :disabled="purchasing"
                         :class="{
                          'bg-gray-50 cursor-not-allowed': purchasing,
                          'border focus:ring-red-500 border-red-500': v$.form.shipping_town_city.$errors.length > 0
                               }"
                         v-model="form.shipping_town_city" name="first-name"
                         id="first-name" autocomplete="first-name"
                         class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                  <div class="mt-1 text-sm text-red-700" v-for="error of v$.form.shipping_town_city.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </div>
                </div>
                <div class="col-span-6 lg:col-span-3">
                  <label for="county-region" class="block text-sm font-medium leading-6 text-gray-900">County/Region
                    <span class="ml-1 text-red-700">*</span>
                  </label>
                  <input type="text"
                         :disabled="purchasing"
                         :class="{
                          'bg-gray-50 cursor-not-allowed': purchasing,
                          'border focus:ring-red-500 border-red-500': v$.form.shipping_county_region.$errors.length > 0
                               }"
                         v-model="form.shipping_county_region" name="first-name"
                         id="first-name" autocomplete="first-name"
                         class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                  <div class="mt-1 text-sm text-red-700" v-for="error of v$.form.shipping_county_region.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </div>
                </div>
                <div class="col-span-6 lg:col-span-3">
                  <label for="postcode" class="block text-sm font-medium leading-6 text-gray-900">Postcode<span
                      class="ml-1 text-red-700">*</span>
                  </label>
                  <input type="text"
                         :disabled="purchasing"
                         :class="{
                          'bg-gray-50 cursor-not-allowed': purchasing,
                          'border focus:ring-red-500 border-red-500': v$.form.shipping_postcode.$errors.length > 0
                               }"
                         v-model="form.shipping_postcode" name="first-name"
                         id="first-name" autocomplete="first-name"
                         class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                  <div class="mt-1 text-sm text-red-700" v-for="error of v$.form.shipping_postcode.$errors" :key="error.$uid">
                    {{ error.$message }}
                  </div>
                </div>

              </div>

            </div>
          </div>


        </div>

        <!-- Order summary -->
        <div class="mt-10 lg:mt-0">
          <h2 class="text-lg font-medium text-gray-900">Order summary</h2>

          <div class="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
            <div class="p-6" v-show="calculating_basket">
              <spin-loader />
            </div>
           <div v-show="!calculating_basket">
             <h3 class="sr-only">Items in your cart</h3>
             <ul role="list" class="divide-y divide-gray-200">

               <li class="flex py-6 px-4 sm:px-6"
                   :key="'basket-item-' + k"
                   v-for="(x, k) of basket_items">
                 <div class="flex-shrink-0">
                   <img  class="w-14 rounded-md"
                         :src="'/images/products/' + x.id + '.png'" :alt="x.title" :title="x.title" />
                 </div>

                 <div class="ml-6 flex flex-1 flex-col">
                   <div class="flex">
                     <div class="min-w-0 flex-1">
                       <h4 class="text-sm">
                         <span class="font-medium text-gray-700 hover:text-gray-800">
                          {{ x.title }}
                         </span>
                       </h4>
                       <p class="mt-1 text-sm text-gray-500">{{ toCurrency(x.price)  }} excl. VAT each</p>
                     </div>

                     <div class="ml-4 flow-root flex-shrink-0">
                       <button
                           v-if="!purchasing"
                           @click="removeItem(x.id)" type="button" class="-m-2.5 flex items-center justify-center bg-white p-2.5 text-gray-400 hover:text-gray-500">
                         <span class="sr-only">Remove</span>
                         <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                           <path fill-rule="evenodd" d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z" clip-rule="evenodd"></path>
                         </svg>
                       </button>
                     </div>
                   </div>

                   <div class="flex flex-1 items-end justify-between pt-2">
                     <p class="mt-1 text-sm font-medium text-gray-900">{{ toCurrency(x.subtotal) }} excl. VAT</p>
                     <div class="ml-4">
                       <label for="quantity" class="sr-only">Quantity</label>
                       <select
                           @change="onQuantityChange(x)"
                           v-model="x.quantity"
                           :disabled="purchasing"
                           :class="{
                             'cursor-not-allowed': purchasing
                           }"
                          class="rounded-md border border-gray-300 text-left text-base font-medium text-gray-700 shadow-sm focus:border-intelli-green focus:outline-none focus:ring-1 focus:ring-intelli-green sm:text-sm">
                         <option :key="'quantity-' + x" v-for="x of x.max_quantity" :value="x" :selected="x.quantity === x ? 'selected' : null">{{ x }}</option>
                       </select>
                     </div>
                   </div>
                 </div>
               </li>

             </ul>
             <dl class="space-y-6 border-t border-gray-200 py-6 px-4 sm:px-6">
               <div class="flex items-center justify-between">
                 <dt class="text-sm">Subtotal</dt>
                 <dd class="text-sm font-medium text-gray-900">{{ toCurrency(subtotal) }}</dd>
               </div>
               <div class="flex items-center justify-between">
                 <dt class="text-sm">Next-day Shipping</dt>
                 <dd class="text-sm font-medium text-gray-900">
                   Free
                 </dd>
               </div>
               <div class="flex items-center justify-between">
                 <dt class="text-sm">VAT (20%)</dt>
                 <dd class="text-sm font-medium text-gray-900">{{ toCurrency(vat) }}</dd>
               </div>
               <div class="flex items-center justify-between border-t border-gray-200 pt-6">
                 <dt class="text-base font-medium">Total</dt>
                 <dd class="text-base font-medium text-gray-900">{{ toCurrency(total)  }}
                 </dd>
               </div>
             </dl>


             <div
                 class="border-t border-gray-200 py-6 px-4 sm:px-6">
                 <h2 class="text-lg font-medium text-gray-900 mb-3">Payment</h2>

                 <div class="bg-gray-50 relative rounded-lg overflow-hidden p-4" v-if="card_type === null">
                   <div v-if="purchasing" class="bg-gray-200 absolute flex items-center justify-center top-0 left-0 w-full h-full z-30 bg-opacity-80">
                     <spin-loader />
                   </div>
                   <StripeElements
                       v-if="stripe_loaded"
                       ref="elms"
                       v-slot="{ elements }"
                       :stripe-key="stripe_key"
                       :instance-options="instanceOptions"
                       :elements-options="elementsOptions"
                   >

                     <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                       <div class="col-span-3">
                         <div>
                           <label class="block text-sm font-medium text-gray-700">
                             Cardholder Name
                           </label>
                           <div class="mt-1">
                             <input type="text" name="first-name" id="first-name"
                                    autocomplete="given-name"
                                    v-model="cardOptions.name"
                                    :class="{
                                                           'border-red-700 text-red-700': invalidCardholderName
                                                           }"
                                    class="block w-full py-3 px-4 rounded-md border-gray-300 shadow-sm focus:ring-gray-500 sm:text-sm">
                             <div
                                 v-if="invalidCardholderName"
                                 class="text-xs ml-1 leading-tight mt-1 text-red-700">
                               Please enter the card holder name
                             </div>
                           </div>
                         </div>
                       </div>

                       <div class="col-span-3">
                         <div>
                           <label class="block text-sm font-medium text-gray-700">
                             Card Number
                           </label>
                           <div class="mt-1">
                             <div class="relative">
                               <StripeElement
                                   @change="onStripeInputChange"
                                   type="cardNumber"
                                   ref="card"
                                   :elements="elements"
                                   :options="cardOptions"
                               />
                               <div
                                   v-if="input.cardNumber.brand && input.cardNumber.brand !== 'unknown'"
                                   class="absolute top-0 right-0 h-full flex items-center px-3">
                                 <card-icon :type="input.cardNumber.brand" />
                               </div>
                             </div>
                             <div
                                 v-if="input.cardNumber.error && input.cardNumber.error.message"
                                 class="text-xs ml-1 text-red-700">
                               {{ input.cardNumber.error.message }}
                             </div>
                           </div>
                         </div>
                       </div>
                       <div class="col-span-3 md:col-span-1">
                         <div>
                           <label class="block text-sm font-medium text-gray-700">Expiry Date</label>
                           <div class="mt-1">
                             <StripeElement
                                 @change="onStripeInputChange"
                                 type="cardExpiry"
                                 :elements="elements"
                                 :options="cardOptions"
                             />
                             <div
                                 v-if="input.cardExpiry.error && input.cardExpiry.error.message"
                                 class="text-xs ml-1 leading-tight mt-1 text-red-700">
                               {{ input.cardExpiry.error.message }}
                             </div>
                           </div>
                         </div>
                       </div>
                       <div class="col-span-3 md:col-span-1">
                         <div>
                           <label class="block text-sm font-medium text-gray-700">CVC</label>
                           <div class="mt-1">
                             <StripeElement
                                 @change="onStripeInputChange"
                                 type="cardCvc"
                                 :elements="elements"
                                 :options="cardOptions"
                             />
                             <div
                                 v-if="input.cardCvc.error && input.cardCvc.error.message"
                                 class="text-xs ml-1 leading-tight mt-1 text-red-700">
                               {{ input.cardCvc.error.message }}
                             </div>
                           </div>
                         </div>
                       </div>
                       <div class="col-span-3 md:col-span-1">
                         <div>
                           <label class="block text-sm font-medium text-gray-700">Postal Code</label>
                           <div class="mt-1">
                             <StripeElement
                                 @change="onStripeInputChange"
                                 type="postalCode"
                                 :elements="elements"
                                 :options="cardOptions"
                             />
                             <div
                                 v-if="input.postalCode.error && input.postalCode.error.message"
                                 class="text-xs ml-1 leading-tight mt-1 text-red-700">
                               {{ input.postalCode.error.message }}
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>

                   </StripeElements>
                 </div>

             </div>

             <div
                 v-if="stripe_loaded"
                 class="border-t border-gray-200 py-6 px-4 sm:px-6">
               <button
                   @click="purchase"
                   :disabled="purchasing"
                   :class="{
                     'bg-opacity-50 cursor-not-allowed': purchasing,
                     'hover:bg-intelli-green-dark': !purchasing
                   }"
                   class="w-full flex items-center justify-center rounded-md border border-transparent bg-intelli-green py-3 px-4 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-intelli-green focus:ring-offset-2 focus:ring-offset-gray-50">
                <span v-if="purchasing">
                  <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
                </span>
                 <span>Confirm Order</span>
               </button>
             </div>
           </div>
          </div>
        </div>
      </div>

    </template>
  </page-layout>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import { StripeElements, StripeElement } from 'vue-stripe-js'
import PageLayout from "@/Layout/PageLayout";
import PageHeader from "@/components/PageHeader";
import {useAuthStore} from "@/store";
import CardIcon from "@/components/CardIcon";

import {required, minLength, helpers } from '@vuelidate/validators'
import {useVuelidate} from '@vuelidate/core'
import {isValid} from "postcode";
import SpinLoader from "@/components/SpinLoader";
import EmptyState from "@/components/EmptyState";
import {handleServerError, notify} from "@/utils/notification";
import {useMeta} from "vue-meta";

export default {
  name: "YourBasket",
  components: {
    EmptyState,
    SpinLoader,
    CardIcon,
    PageHeader, PageLayout,
    StripeElements, StripeElement
    },
  setup() {
    const store = useAuthStore();
    useMeta({
      title: 'Your Basket'
    })
    return {
      basket: store,
      v$: useVuelidate()
    }
  },
  validations() {

    let shipping  = {
      shipping_first_name: {
        required: helpers.withMessage('A first name is required', required),
        minLength: minLength(3)
      },
      shipping_last_name: {
        required: helpers.withMessage('A last name is required', required),
        minLength: minLength(3)
      },
      shipping_company_name: {
        required: helpers.withMessage('A company name is required', required),
        minLength: minLength(3)
      },
      shipping_address_line_one: {
        required: helpers.withMessage('Address line one is required', required),
      },
      shipping_town_city: {
        required: helpers.withMessage('Town/City is required', required),
      },
      shipping_county_region: {
        required: helpers.withMessage('County/Region is required', required),
      },
      shipping_postcode: {
        required: helpers.withMessage('Postcode is required', required),
        validPostcode: helpers.withMessage('A valid postcode is required', isValid)
      }
    };
    let billing  = {
      billing_first_name: {
        required: helpers.withMessage('A first name is required', required),
        minLength: minLength(3)
      },
      billing_last_name: {
        required: helpers.withMessage('A last name is required', required),
        minLength: minLength(3)
      },
      billing_company_name: {
        required: helpers.withMessage('A company name is required', required),
        minLength: minLength(3)
      },
      billing_address_line_one: {
        required: helpers.withMessage('Address line one is required', required),
      },
      billing_town_city: {
        required: helpers.withMessage('Town/City is required', required),
      },
      billing_county_region: {
        required: helpers.withMessage('County/Region is required', required),
      },
      billing_postcode: {
        required: helpers.withMessage('Postcode is required', required),
        validPostcode: helpers.withMessage('A valid postcode is required', isValid)
      }
    };

    if(!this.shipping_address_same){
      billing = Object.assign(billing, shipping);
    }

    return {
      form: billing
    }
  },
  beforeMount() {
    const stripePromise = loadStripe(this.stripe_key)
    stripePromise.then(() => {
      console.log("loaded")
      this.stripe_loaded = true;
    })
  },
  data() {
    return {
      fetching_user_details: true,
      shipping_address_same: true,
      form: {
        billing_company_name: null,
        billing_first_name: null,
        billing_last_name: null,
        billing_address_line_one: null,
        billing_address_line_two: null,
        billing_town_city: null,
        billing_county_region: null,
        billing_postcode: null,
        shipping_company_name: null,
        shipping_first_name: null,
        shipping_last_name: null,
        shipping_address_line_one: null,
        shipping_address_line_two: null,
        shipping_town_city: null,
        shipping_county_region: null,
        shipping_postcode: null,
    },
      purchasing: false,
      stripe_key: process.env.NODE_ENV === 'production' ? 'pk_live_51MY6LoHwyzmFqZdztgUIuzNNhyNNfPYeZYiF4LtIVJgOYGXAsWguQQcSij553smOpLePmAduBy0Rhzfrq15qZBwG00YioTvTEt' : 'pk_test_51MY6LoHwyzmFqZdz2DT6ESxHuDsFnBVfzVEseXEVfwwMNyW7bZoql7tI2JrhXPA77j5VsAycog5hlZ3cCkboL0W800WXZ0Kbt2',
      stripe_loaded: false,
      calculating_basket: true,
      basket_items: [],
      existing_cards: [],
      has_account: false,
      card_type: null,
      loading: true,
      show_card_dropdown: false,
      instanceOptions: {
        // https://stripe.com/docs/js/initializing#init_stripe_js-options
      },
      elementsOptions: {
        // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
      },
      input: {
        cardNumber: {},
        cardExpiry: {},
        cardCvc: {},
        postalCode: {},
      },
      cardOptions: {
        name: '',
        classes: {
          base: 'block w-full py-3 px-4 rounded-md border border-gray-300 shadow-sm sm:text-sm bg-white',
          focus: 'border-gray-500',
          invalid: 'border-red-700',
          complete: 'border-green-700',
        },
      },
      total: 0,
      vat: 0,
      subtotal: 0,
      shipping: 0
    }
  },
  computed: {
    orderConfirmed(){
      return this.basket.totalBasketItems === 0 && this.$route.query.ordered && this.$route.query.ordered === 'true';
    },
    invalidCardholderName() {
      return !this.cardOptions.name || !this.cardOptions.name.toString().length || !this.cardOptions.name.replace(/\s/g, '').length > 0;
    },
    findSelectedColor(){
      let find = this.color.find((x) => {
        return x.value === this.selected.color;
      });
      return find ? find.label : "";
    },
    getSelectedCardDetails() {
      const vm = this;
      const find = this.existing_cards.find((x) => x.id === vm.card_type);
      return find || null;
    },
  },
  mounted() {
    this.calculate();
    this.fetchUser();
  },
  methods: {
  toCurrency(value, locale, currency) {
  locale = locale || 'en-GB';
  currency = currency || 'GBP';
  return new Intl.NumberFormat(locale, { style: 'currency', currency,
    minimumFractionDigits: 2
  }).format(value);
},
    removeItem(id){
      this.basket.setRemoveBasketItem(id);
      this.calculate();
    },
    onQuantityChange(item){
      this.basket.setBasketQuantity(item.id, item.quantity);
      this.calculate();
    },
    onStripeInputChange(e) {
      this.input[e.elementType] = e;
    },

    async purchase() {
      const vm = this,
          validated = await vm.v$.$validate();
      if(!validated){
        return;
      }
      if(vm.invalidCardholderName){
        return;
      }

      vm.purchasing = true;

      // ref in template
      const elms = this.$refs.elms;
      const card = this.$refs.card;
      // Get stripe element
      const cardElement = card.stripeElement;

      // Access instance methods, e.g. createToken()
      elms.instance.createToken(cardElement, { name: this.cardOptions.name }).then((result) => {
        // Handle result.error or result.token
        if (result.error) {
          vm.purchasing = false;
        } else {
          if (result.token && result.token && result.token.id) {
            return this.makePayment(result.token.id);
          }
          vm.purchasing = false;
        }
      });

      // Validate Stripe

    },
    makePayment(token){

    if(!token){
      return;
    }

    let vm = this,
        object = JSON.parse(JSON.stringify(vm.form));

      vm.$http.post('/api/v1/basket/purchase', Object.assign({
        token,
        shipping_address_same: vm.shipping_address_same,
        basket: this.basket.basketObject,
      }, object))
          .then(() => {
            vm.basket.emptyBasket();

            vm.$router.push("/basket?ordered=true")
            setTimeout(() => {
              notify({
                title: 'Order Processing',
                message: 'Thank you! Your order is being processed. Please check your inbox.'
              });
            }, 10)
            vm.purchasing = false;

          }).catch((response) => {
        vm.purchasing = false;
        handleServerError(response);
      })
    },
    fetchUser(){
      const vm = this;
      vm.fetching_user_details = true;
      vm.$http.get('/api/v1/auth/user')
          .then((response) => {
            vm.form.billing_first_name = response.data.data.contact.first_name;
            vm.form.billing_last_name = response.data.data.contact.last_name;

            vm.form.billing_company_name = response.data.data.company.company_name;

            let trading_name =  response.data.data.company.trading_name;
            if(trading_name && trading_name.length > 0){
              vm.form.billing_company_name += " t/a " + trading_name;
            }

            vm.form.billing_address_line_one = response.data.data.company.address_line_one;
            vm.form.billing_address_line_two = response.data.data.company.address_line_two;
            vm.form.billing_town_city = response.data.data.company.address_city;
            vm.form.billing_county_region = response.data.data.company.address_state;
            vm.form.billing_postcode = response.data.data.company.address_post_code;

            vm.fetching_user_details = false;
          }).catch((response) => {
        handleServerError(response);
      })
    },
      calculate(){
      const vm = this;
      vm.calculating_basket = true;

      vm.$http.post('/api/v1/basket/calculate',  {
        items: this.basket.basketObject
      }).then((response) => {
        let object = response.data.data.basket;
        vm.basket_items = response.data.data.list_items;
        vm.total = response.data.data.total;
        vm.shipping = response.data.data.shipping;
        vm.subtotal = response.data.data.sub_total;
        vm.vat = response.data.data.vat;
        vm.basket.setBasketObject(object);
        vm.calculating_basket = false;
      }).catch((response) => {
        handleServerError(response);
      })
    }
  }
}
</script>