<template>
  <page-layout>
    <template v-slot:content>

      <page-header>
        <template v-slot:icon>
          <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"></path></svg>
        </template>
        <template v-slot:title>
         Marketing
        </template>
      </page-header>

      <div class="my-5">
        <h4 class="mb-4 text-lg font-bold">Leaflets</h4>

        <h4 class="mb-4 font-bold">EVS II</h4>

        <ul class="grid mb-3 grid-cols-2 lg:grid-cols-4 gap-6">
          <li :key="i" v-for="(x, i) of leaflets">

            <download-item :name="x.name" :url="x.url">
              <svg xmlns="http://www.w3.org/2000/svg"
                   class="w-10 h-10"
                   viewBox="0 0 24 24"><path d="M6 22v-16h16v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-7.362zm18-7.614v-10.386h-20v20h10.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-5v-1h5v1zm5-4h-10v1h10v-1zm0-3h-10v1h10v-1zm2-7h-19v19h-2v-21h21v2z"/></svg>
            </download-item>

          </li>
        </ul>

        <h4 class="mb-4 text-lg font-bold">Images</h4>

        <h4 class="mb-4 font-bold">EVS II</h4>

        <ul class="grid mb-3 grid-cols-2 lg:grid-cols-4 gap-6">
          <li :key="i" v-for="(x, i) of images.evsii">

            <download-item :name="x.file_name" :url="'/images/marketing/EVSII/' + x.file_name + '.png'">
              <img :src="'/images/marketing/EVSII/' + x.file_name + '-100x100.png'" :alt="x.file_name" :title="x.file_name">
           </download-item>

          </li>
        </ul>


        <h4 class="mt-8 mb-4 text-lg font-bold">Branding</h4>

        <ul class="grid mb-3 grid-cols-2 lg:grid-cols-4 gap-6">
          <li :key="i" v-for="(x, i) of branding">

            <download-item :name="x.name" :url="'/images/marketing/Branding/' + x.file_name + '.png'">
              <img :src="'/images/marketing/Branding/' + x.file_name + '.png'" :alt="x.file_name" :title="x.file_name">
            </download-item>

          </li>
        </ul>

      </div>

    </template>
  </page-layout>
</template>

<script>
import PageLayout from "@/Layout/PageLayout";
import PageHeader from "@/components/PageHeader";
import {useMeta} from "vue-meta";
import DownloadItem from "@/components/DownloadItem";

export default {
  name: "MarketingTools",
  components: {DownloadItem, PageHeader, PageLayout},
  setup(){
    useMeta({
      title: 'Marketing Tools'
    })
  },
  data() {
    return {
      leaflets: [
        {name: 'All-black Marketing 2023', url: 'https://intelliev.ams3.cdn.digitaloceanspaces.com/documentation/manuals/EVSII-Info-Sheet-High-Print.pdf'},
        {name: 'Q2 2023', url: 'https://intelliev.ams3.cdn.digitaloceanspaces.com/documentation/manuals/Carbon%20Edition%20Flyer-2023.pdf'},
        {name: 'Q2 Automotive 2023', url: 'https://intelliev.ams3.cdn.digitaloceanspaces.com/documentation/manuals/EV7S%20Series%20II%20Carbon%20Edition.pdf'},
      ],
      branding: [
        { file_name: 'logo', name: 'Logo'},
        { file_name: 'logo-strap-line', name: 'Logo Strapline'}
      ],
      images: {
        evsii: [
          { file_name: 'EVSII-01'},
          { file_name: 'EVSII-02'},
          { file_name: 'EVSII-03'},
          { file_name: 'EVSII-04'},
          { file_name: 'EVSII-05'},
          { file_name: 'EVSII-06'},
        ]
      }
    }
  }
}
</script>