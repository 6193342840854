import axios from 'axios'
import { useAuthStore } from '@/store/stores/auth'

import router from '@/router/index'


axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'https://portal-api.intelliev.uk' : 'http://localhost';

// Append the Access Token on all API Requests
axios.interceptors.request.use( (config) => {

  const auth = useAuthStore();

  if (auth.getAccessToken()) {
    config.headers.Authorization = 'Bearer ' + auth.getAccessToken();
  }

  return config

}, (error) => {
  return Promise.reject(error)
})

// Handle Refresh token here.
axios.interceptors.response.use((response) => {
  return response
}, (error) => {

  const auth = useAuthStore();

  const originalRequest = error.config;

  if (error.response) {
    console.log('error', error.response)

    console.log('originalRequest.url', originalRequest.url)
    console.log('originalRequest._retry', originalRequest._retry)

    if (error.response.status === 401 && originalRequest.url === '/api/v1/auth/refresh-token') {
      router.push('/login?mode=refresh-expired&redirect=/');
      return Promise.reject(error)
    }

    if (error.response.status === 401 && !originalRequest._retry) {

      try {
        console.log('here', auth.getRefreshToken());
      }catch (e) {
        console.warn(e);
      }

      originalRequest._retry = true;

      return axios.post('/api/v1/auth/refresh-token',
          {
            refresh_token: auth.getRefreshToken()
          })
          .then(response => {

            auth.setAccessToken(response.data.data.token);
            auth.setRefreshToken(response.data.data.refresh_token);

            return axios(originalRequest);

          })
          .catch(() => {
            console.log("Here")
            return router.push('/login?logout=true&redirect=/');
            //return Promise.reject(error);
          })

    }
  }
  return Promise.reject(error)
})

export default axios
