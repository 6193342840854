<template>
  <page-layout>
    <template v-slot:content>

      <page-header>
        <template v-slot:title>
          Create New User
        </template>
      </page-header>


      <div class="my-6">
        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6 lg:col-span-3">
            <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">First name<span
                class="ml-1 text-red-700">*</span>
            </label>
            <input type="text"
                   :disabled="running"
                   :class="{
                          'bg-gray-50 cursor-not-allowed': running,
                          'border focus:ring-red-500 border-red-500': v$.first_name.$errors.length
                               }"
                   v-model="first_name" name="first-name"
                   id="first-name" autocomplete="first-name"
                   class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
            <div class="text-xs text-red-700 mt-2"
                 v-if="v$.first_name.$errors.length">
              {{ v$.first_name.$errors[0].$message }}
            </div>
          </div>

          <div class="col-span-6 lg:col-span-3">
            <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Last name <span
                class="ml-1 text-red-700">*</span></label>
            <input type="text"
                   :disabled="running"
                   :class="{
                          'bg-gray-50 cursor-not-allowed': running,
    'border focus:ring-red-500 border-red-500': v$.last_name.$errors.length && v$.last_name.$errors.length
                               }"
                   v-model="last_name" name="last-name"
                   id="last-name" autocomplete="family-name"
                   class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
            <div class="text-xs text-red-700 mt-2"
                 v-if="v$.last_name.$errors.length && v$.last_name.$errors.length">
              {{ v$.last_name.$errors[0].$message }}
            </div>
          </div>


          <div class="col-span-6 lg:col-span-3">
            <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address<span
                class="ml-1 text-red-700">*</span></label>
            <input type="email"
                   :disabled="running"
                   :class="{
                          'bg-gray-50 cursor-not-allowed': running,
           'border focus:ring-red-500 border-red-500': v$.email.$errors.length
                               }"
                   v-model="email" name="email"
                   id="email" autocomplete="email"
                   class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
            <div class="text-xs text-red-700 mt-2"
                 v-if="v$.email.$errors.length">
              {{ v$.email.$errors[0].$message }}
            </div>

          </div>

          <div class="col-span-6 lg:col-span-3">
            <label for="tel-number" class="block text-sm font-medium leading-6 text-gray-900">Telephone number<span
                class="ml-1 text-red-700">*</span></label>
            <input type="tel"
                   :disabled="running"
                   :class="{
                          'bg-gray-50 cursor-not-allowed': running,
     'border focus:ring-red-500 border-red-500': v$.tel.$errors.length
                               }"
                   v-model="tel"
                   name="tel-number"
                   id="tel-number" autocomplete="tel"
                   class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
            <div class="text-xs text-red-700 mt-2"
                 v-if="v$.tel.$errors.length">
              {{ v$.tel.$errors[0].$message }}
            </div>
          </div>

          <div class="col-span-6">
            <label for="company-name" class="block text-sm font-medium leading-6 text-gray-900">Company
              name<span class="ml-1 text-red-700">*</span>
            </label>
            <input type="text"
                   :disabled="running"
                   :class="{
                          'bg-gray-50 cursor-not-allowed': running,
                          'border focus:ring-red-500 border-red-500': v$.company_name.$errors.length &&
                          v$.company_name.$errors.length
                               }"
                   v-model="company_name"
                   name="company-name"
                   id="company-name"
                   autocomplete="organization"
                   class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
            <div class="text-xs text-red-700 mt-2"
                 v-if="v$.company_name.$errors.length && v$.company_name.$errors.length">
              {{ v$.company_name.$errors[0].$message }}
            </div>
          </div>

          <div class="col-span-6">
            <label for="trading-name" class="block text-sm font-medium leading-6 text-gray-900">
              Trading name (If different to company name)
            </label>
            <input type="text"
                   name="trading-name"
                   :disabled="running"
                   :class="{
                          'bg-gray-50 cursor-not-allowed': running
                          }"
                   v-model="trading_name"
                   id="trading-name"
                   autocomplete="trading-name"
                   class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
          </div>

          <div class="col-span-6 lg:col-span-3">
            <label for="company-number" class="block text-sm font-medium leading-6 text-gray-900">
              Company number
            </label>
            <input type="text"
                   name="company-number"
                   id="company-number"
                   :disabled="running"
                   :class="{
                          'bg-gray-50 cursor-not-allowed': running
                          }"
                   v-model="company_number"
                   autocomplete="company-number"
                   class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
          </div>

          <div class="col-span-6 lg:col-span-3">
            <label for="website" class="block text-sm font-medium leading-6 text-gray-900">Website
            </label>
            <input type="text"
                   :disabled="running"
                   :class="{
                          'bg-gray-50 cursor-not-allowed': running,
                          'border focus:ring-red-500 border-red-500': v$.website.$errors.length &&
                          v$.website.$errors.length
                               }"
                   v-model="website"
                   name="website"
                   id="website"
                   autocomplete="url"
                   class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
            <div class="text-xs text-red-700 mt-2"
                 v-if="v$.website.$errors.length && v$.website.$errors.length">
              {{ v$.website.$errors[0].$message }}
            </div>
          </div>


          <div class="col-span-6">
            <label for="address-line-one" class="block text-sm font-medium leading-6 text-gray-900">Address
              Line One<span class="ml-1 text-red-700">*</span>
            </label>
            <input type="text"
                   :disabled="running"
                   :class="{
                          'bg-gray-50 cursor-not-allowed': running,
                          'border focus:ring-red-500 border-red-500': v$.address_line_one.$errors.length &&
                          v$.address_line_one.$errors.length
                               }"
                   v-model="address_line_one"
                   name="address-line-one"
                   id="address-line-one"
                   autocomplete="address-line1"
                   class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
            <div class="text-xs text-red-700 mt-2"
                 v-if="v$.address_line_one.$errors.length && v$.address_line_one.$errors.length">
              {{ v$.address_line_one.$errors[0].$message }}
            </div>
          </div>

          <div class="col-span-6">
            <label for="address-line-two" class="block text-sm font-medium leading-6 text-gray-900">
              Address Line Two
            </label>
            <input
                :disabled="running"
                :class="{
                          'bg-gray-50 cursor-not-allowed': running
                          }"
                v-model="address_line_two"
                type="text" name="address-line-two" id="address-line-two" autocomplete="address-line2"
                class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
          </div>

          <div class="col-span-6 lg:col-span-3">
            <label for="town-city" class="block text-sm font-medium leading-6 text-gray-900">Town/City<span
                class="ml-1 text-red-700">*</span>
            </label>
            <input type="text"
                   :disabled="running"
                   :class="{
                          'bg-gray-50 cursor-not-allowed': running,
                          'border focus:ring-red-500 border-red-500': v$.town_city.$errors.length &&
                          v$.town_city.$errors.length
                               }"
                   v-model="town_city"
                   name="town-city"
                   id="town-city"
                   autocomplete="address-line3"
                   class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
            <div class="text-xs text-red-700 mt-2"
                 v-if="v$.town_city.$errors.length && v$.town_city.$errors.length">
              {{ v$.town_city.$errors[0].$message }}
            </div>
          </div>

          <div class="col-span-6 lg:col-span-3">
            <label for="county-region" class="block text-sm font-medium leading-6 text-gray-900">County/Region<span
                class="ml-1 text-red-700">*</span>
            </label>
            <input type="text"
                   :disabled="running"
                   :class="{
                          'bg-gray-50 cursor-not-allowed': running,
                          'border focus:ring-red-500 border-red-500': v$.county_region.$errors.length &&
                          v$.county_region.$errors.length
                               }"
                   v-model="county_region"
                   name="county-region"
                   id="county-region"
                   autocomplete="address-line4"
                   class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
            <div class="text-xs text-red-700 mt-2"
                 v-if="v$.county_region.$errors.length && v$.county_region.$errors.length">
              {{ v$.county_region.$errors[0].$message }}
            </div>
          </div>

          <div class="col-span-6 lg:col-span-3">
            <label for="postcode" class="block text-sm font-medium leading-6 text-gray-900">Postcode<span
                class="ml-1 text-red-700">*</span>
            </label>
            <input type="text"
                   :disabled="running"
                   :class="{
                          'bg-gray-50 cursor-not-allowed': running,
                          'border focus:ring-red-500 border-red-500': v$.postcode.$errors.length &&
                          v$.postcode.$errors.length
                               }"
                   v-model="postcode"
                   name="postcode"
                   id="postcode"
                   autocomplete="postal-code"
                   class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
            <div class="text-xs text-red-700 mt-2"
                 v-if="v$.postcode.$errors.length && v$.postcode.$errors.length">
              {{ v$.postcode.$errors[0].$message }}
            </div>
          </div>
        </div>
      </div>

      <button
          :disabled="running"
          @click="validate"
          class="justify-center rounded-md bg-green-600 py-3 px-4 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500">
        Create Account
      </button>



    </template>
  </page-layout>
</template>

<script>
import PageLayout from "@/Layout/PageLayout";
import PageHeader from "@/components/PageHeader";
import {useMeta} from "vue-meta";
import {useAuthStore} from "@/store";
import {email, helpers, minLength, required, url} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {isValid} from "postcode";
import {handleServerError, notify} from "@/utils/notification";

export default {
  name: "AdminCreateUser",
  components: {PageHeader, PageLayout},
  setup() {
    const store = useAuthStore();
    console.log(store);
    useMeta({
      title: 'Create New User'
    })
    return {
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      first_name: {
        required: helpers.withMessage('A first name is required', required),
        minLength: minLength(3)
      },
      last_name: {
        required: helpers.withMessage('A last name is required', required),
        minLength: minLength(3)
      },
      email: {
        required: helpers.withMessage('An email address is required', required),
        email: helpers.withMessage('A valid email address is required', email)
      },
      tel: {
        required: helpers.withMessage('A UK Phone number is required', required),
        phoneNumber: helpers.withMessage('Please enter a valid UK Phone number', (value) => {
          value = value.replace(/\s/g, "");
          let regex = /^\+?(?:\d\s?){10,12}$/i;
          return regex.test(value);
        })
      },
      company_name: {
        required: helpers.withMessage('A company name is required', required),
      },
      address_line_one: {
        required: helpers.withMessage('Address line one is required', required),
      },
      town_city: {
        required: helpers.withMessage('Town/City is required', required),
      },
      county_region: {
        required: helpers.withMessage('County/Region is required', required),
      },
      website: {
        url: helpers.withMessage('A valid website URL starting with http/https is required', url),
      },
      postcode: {
        required: helpers.withMessage('Postcode is required', required),
        validPostcode: helpers.withMessage('A valid postcode is required', isValid)
      },
    }
  },
  data() {
    return {
      first_name: null,
      last_name: null,
      email: null,
      tel: null,
      company_name: null,
      company_number: null,
      trading_name: null,
      website: null,
      address_line_one: null,
      address_line_two: null,
      town_city: null,
      county_region: null,
      postcode: null,
      running: false,
      errors: {}
    }
  },
  methods: {
    async validate() {
      const result = await this.v$.$validate();
      if (result) {
        this.running = false;

        this.$http.post('/api/v1/admin/user', {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          tel: this.tel,
          company_name: this.company_name,
          company_number: this.company_number,
          trading_name: this.trading_name,
          website: this.website,
          address_line_one: this.address_line_one,
          address_line_two: this.address_line_two,
          town_city: this.town_city,
          county_region: this.county_region,
          postcode: this.postcode,
        })
            .then(() => {

              this.first_name = null;
              this.last_name = null;
              this.email = null;
              this.tel = null;
              this.company_name = null;
              this.company_number = null;
              this.trading_name = null;
              this.website = null;
              this.address_line_one = null;
              this.address_line_two = null;
              this.town_city = null;
              this.county_region = null;
              this.postcode = null;
              this.running = false;

              notify({
                title: 'User Created Successfully'
              });
            })
            .catch((response) => {
              this.running = false;
              handleServerError(response);
            })
      }
    }
  }
}
</script>