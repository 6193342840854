<template>
  <div class="sm:flex sm:items-center">
    <div class="mr-2">
      <slot name="icon"></slot>
    </div>
    <div class="sm:flex-auto">
      <h1 class="text-2xl font-semibold text-gray-900">
        <slot name="title"></slot>
      </h1>
    </div>
    <div class="mt-4 flex gap-x-3 sm:mt-0 sm:ml-16 sm:flex-none">
      <slot name="cta"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "PageHeader"
}
</script>

<style scoped>

</style>