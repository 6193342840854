<template>
  <div class="grid grid-cols-3 gap-3 sm:grid-cols-3">
    <!--
      In Stock: "cursor-pointer", Out of Stock: "opacity-25 cursor-not-allowed"
      Active: "ring-2 ring-intelli-blue ring-offset-2"
      Checked: "border-transparent bg-intelli-blue text-white hover:bg-indigo-700",
      Not Checked: "border-gray-200 bg-white text-gray-900 hover:bg-gray-50"
    -->
    <label
        :class="{
                'cursor-pointer hover:bg-gray-100': p.available,
                'opacity-25 cursor-not-allowed': !p.available,
                'ring-2 ring-intelli-blue ring-offset-2 border-transparent bg-intelli-blue text-white hover:bg-intelli-blue': p.value === selected
                  }"
        :key="'power-' + p.value" v-for="p of options" class="flex items-center justify-center rounded-md border py-3 px-3 text-sm font-medium sm:flex-1 focus:outline-none">
      <input type="radio" name="size-choice" v-model="selected" :value="p.value" class="sr-only"
             :disabled="!p.available"
             :aria-labelledby="'power-label-' + p.value">
      <span  :id="'power-label-' + p.value" >{{ p.label }}</span>
    </label>

  </div>
</template>

<script>
export default {
  name: "ProductOptionsComponent",
  props: {
    defaultValue: {
      type: String,
      default(){
        return ''
      }
    },
    options: {
      type: Array,
      default(){
        return []
      }
    }
  },
  watch: {
    'selected'(){
      console.log(this.defaultValue)
      this.$emit('change-value', this.selected)
    },
    defaultValue(){
      this.selected = this.defaultValue;
    }
  },
  data(){
    return {
      selected: this.defaultValue
    }
  }
}
</script>

<style scoped>

</style>