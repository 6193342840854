import { createApp } from 'vue'
import Notification from "@/components/AlertNotification";

export function handleServerError(params){
  console.log(params);
  const error = params;

  const defaultOpts = {
    title: 'Sorry, something is wrong;',
    message: 'We had an internal error occurred. Please contact support',
    type: 'error',
  }

  if (error.response) {

    if (error.response.status === 404 && error.response.data && error.response.data.message) {
      const {message} = error.response.data;
      if (message.length > 0) {
        defaultOpts.message = message;
        defaultOpts.type = 'warning';
      }
    }

    if (error.response.status === 400 && error.response.data && error.response.data.message) {
      const message = error.response.data.message.replace('body.', '');
      if (message.length > 0) {
        defaultOpts.message = message;
        defaultOpts.type = 'warning';
      }
    }

    if (error.response.status === 422 && error.response.data && error.response.data.message) {
      const {message} = error.response.data;
      if (message.length > 0) {
        defaultOpts.message = message;
        defaultOpts.type = 'warning';
      }
    }
  }
  notify(defaultOpts)
}

export function notify(params){

  const wrapper = document.createElement("div")

  let app = createApp(Notification, params).mount(wrapper);
  console.log(app.$el);

  let area = document.querySelector('#notification-area');
  area.appendChild(app.$el);
}