<template>
  <page-layout>
    <template v-slot:content>

      <page-header>
        <template v-slot:icon>
          <svg  class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"></path></svg>
        </template>
        <template v-slot:title>
         Documentation
        </template>
      </page-header>

      <div class="my-5">
        <h4 class="mb-4 text-lg font-bold">Manuals & Datasheets</h4>

        <h4 class="mb-4 font-bold">EVS II</h4>

        <ul class="grid mb-3 grid-cols-2 lg:grid-cols-4 gap-6">


          <li :key="i" v-for="(x, i) of manuals">
            <download-item :name="x.name" :url="x.url">
              <svg xmlns="http://www.w3.org/2000/svg"
                   class="w-10 h-10"
                   viewBox="0 0 24 24"><path d="M6 22v-16h16v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-7.362zm18-7.614v-10.386h-20v20h10.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-5v-1h5v1zm5-4h-10v1h10v-1zm0-3h-10v1h10v-1zm2-7h-19v19h-2v-21h21v2z"/></svg>
            </download-item>
          </li>
        </ul>

        <h4 class="my-4 mt-10 text-lg font-bold">Certificates</h4>

        <ul class="grid mb-3 grid-cols-2 lg:grid-cols-4 gap-6">
          <li :key="i" v-for="(x, i) of certificates">

            <download-item :name="x.name" :url="x.url">
              <svg xmlns="http://www.w3.org/2000/svg"
                   class="w-10 h-10"
                   viewBox="0 0 24 24"><path d="M14.969 9.547l.031.191c0 .193-.096.379-.264.496-.538.372-.467.278-.67.885-.084.253-.33.424-.605.424h-.002c-.664-.002-.549-.038-1.083.338-.112.08-.244.119-.376.119s-.264-.039-.376-.118c-.534-.376-.419-.34-1.083-.338h-.002c-.275 0-.521-.171-.605-.424-.203-.607-.133-.513-.669-.885-.169-.118-.265-.304-.265-.497l.031-.19c.207-.604.208-.488 0-1.094l-.031-.191c0-.193.096-.379.265-.497.536-.372.465-.277.669-.885.084-.253.33-.424.605-.424h.002c.662.002.544.041 1.083-.338.112-.08.244-.119.376-.119s.264.039.376.118c.534.376.419.34 1.083.338h.002c.275 0 .521.171.605.424.203.607.132.513.67.885.168.118.264.304.264.497l-.031.191c-.207.604-.208.488 0 1.094zm-1.469-1.198l-.465-.464-1.41 1.446-.66-.627-.465.464 1.125 1.091 1.875-1.91zm4.5 4.651h-12v1h12v-1zm-1 2h-10v1h10v-1zm1 2h-12v1h12v-1zm1-15h-19v20h24v-20h-5zm3 15.422c-1.151.504-2.074 1.427-2.578 2.578h-14.844c-.504-1.151-1.427-2.074-2.578-2.578v-10.844c1.151-.504 2.074-1.427 2.578-2.578h14.844c.504 1.151 1.427 2.074 2.578 2.578v10.844z"/></svg>
            </download-item>

          </li>
        </ul>

      </div>


    </template>
  </page-layout>
</template>

<script>
import PageLayout from "@/Layout/PageLayout";
import PageHeader from "@/components/PageHeader";
import {useMeta} from "vue-meta";
import DownloadItem from "@/components/DownloadItem";

export default {
  name: "InstallerDocumentation",
  components: {DownloadItem, PageHeader, PageLayout},
  setup(){
    useMeta({
      title: 'Installer Documentation'
    })
  },
  data() {
    return {
      manuals: [
        {name: 'Manual', url: 'https://intelliev.ams3.cdn.digitaloceanspaces.com/documentation/manuals/EV7S-II-User-Manual-Small.pdf'},
        {name: 'Monta Setup', url: 'https://intelliev.ams3.cdn.digitaloceanspaces.com/documentation/manuals/EV7S-II-Monta-User-Manual-Print.pdf'},
        {name: 'Spec Sheet', url: 'https://intelliev.ams3.cdn.digitaloceanspaces.com/documentation/manuals/EV7S%20II%20Specification%20Sheet.pdf'},
        {name: 'LED Indicator Sheet', url: 'https://intelliev.ams3.cdn.digitaloceanspaces.com/documentation/LED_Indicator.jpg'},
        {name: 'Equipment Returns Form', url: 'https://intelliev.ams3.cdn.digitaloceanspaces.com/documentation/IntelliEV%20RMA%202023.pdf'}
      ],
      certificates: [
        {name: 'TUV Certification (AK)', url: 'https://intelliev.ams3.cdn.digitaloceanspaces.com/documentation/manuals/AK%2050571101%20001_extsigned.pdf'},
        {name: 'TUV Certification (AW)', url: 'https://intelliev.ams3.cdn.digitaloceanspaces.com/documentation/manuals/AW%2050575141_0001_extsigned.pdf'},
      ]
    }
  }
}
</script>