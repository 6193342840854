<template>
  <a :href="url" target="_blank" class="cursor-pointer w-full h-full flex flex-wrap items-center justify-center text-gray-700 items-center justify-center p-4 bg-white rounded-lg hover:bg-gray-200">
              <div class="block mx-auto my-8">
                <slot></slot>
              </div>
    <span class="block w-full text-center text-sm py-4 overflow-hidden truncate font-semibold text-gray-600 leading-tight text-center">
                {{ name }}
              </span>
  </a>
</template>

<script>
export default {
  name: "DownloadItem",
  props: {
    url: {
      type: String,
      default(){
        return null
      }
    },
    name: {
      type: String,
      default(){
        return null
      }
    },
  }
}
</script>