import axios from 'axios'

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'https://portal-api.intelliev.uk' : 'http://localhost';

export function authenticate (email, password) {
  return new Promise((resolve, reject) => {
    axios.post('/api/v1/auth/login', {
      email: email,
      password: password
    }).then((response) => {
      return resolve(response)
    })
      .catch((e) => {
        console.log(e)
        return reject(e)
      })
  })
}