<template>
  <div class="grid p-12 grid-cols-1 lg:grid-cols-2 gap-6">
    <div>
      <div class="grid grid-cols-1 gap-y-8">
        <div>
          <h2 class="text-3xl text-intelli-green font-bold leading-tight">
            {{ title }}
          </h2>
          <p class="text-sm mt-3" v-if="subtitle && subtitle.length > 0">subtitle</p>
          <div class="flex text-xs mt-3 gap-x-4">
            <span><strong>SKU</strong>: {{ sku }}</span>
            <span><strong>Barcode</strong>: {{ uan }}</span>
          </div>
        </div>
        <div>
          <h2 class="text-sm font-medium text-gray-900">Body color</h2>
          <fieldset class="mt-2">
            <legend class="sr-only">Choose a body color</legend>
            <div class="flex items-center space-x-3">
              <!--
                Active and Checked: "ring ring-offset-1"
                Not Active and Checked: "ring-2"
              -->
              <label
                  :key="'body-color-' + x.value"
                  v-for="x of color"
                  :class="{
                         'ring ring-offset-1 ring-intelli-green-dark': x.value === selected.color,
                         'ring-2': x.value !== selected.body && x.available,
                         'bg-opacity-20 cursor-not-allowed': !x.available
                       }"
                  class="relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none ring-none">
                <input type="radio"
                       :class="{
                                              'opacity-20 cursor-not-allowed': !x.available
                            }"
                       :disabled="!x.available"
                       name="color-choice" :value="x.value" v-model="selected.color" class="sr-only" aria-labelledby="color-choice-0-label">
                <span id="color-choice-0-label" class="sr-only">
                     </span>
                <span
                    :class="{
                       'cursor-not-allowed': !x.available
                         }"
                    :style="{
                       'background-color': x.texture ? null : x.color,
                         }"
                    aria-hidden="true" class="h-8 w-8 rounded-full overflow-hidden border border-black border-opacity-10">
                       <span v-if="x.texture">
                         <img :src="'/images/textures/' + x.texture" />
                       </span>
                     </span>
              </label>

              <div class="text-xs pl-3">
                {{ findSelectedColor }}
              </div>

            </div>
          </fieldset>
        </div>


        <div>
          <h2 class="text-sm font-medium text-gray-900">Power</h2>
          <fieldset class="mt-2">
            <legend class="sr-only">Choose a Power</legend>
            <product-options-component
                :default-value="selected.power"
                @change-value="(val) => selected.power = val" :options="power" />
          </fieldset>
        </div>
        <div>
          <h2 class="text-sm font-medium text-gray-900">Type</h2>
          <fieldset class="mt-2">
            <legend class="sr-only">Chose Type</legend>
            <product-options-component
                :default-value="selected.type"
                @change-value="(val) => selected.type = val" :options="types" />
          </fieldset>
        </div>

        <div>
          <div class="bg-gray-50 rounded">
            <dl class="space-y-6 py-6 px-4 sm:px-6">
              <div class="flex items-center justify-between">
                <dt class="text-sm">Subtotal</dt>
                <dd class="text-sm font-medium text-gray-900">
                  {{ toCurrency(price) }}
                </dd>
              </div>
              <div class="flex items-center justify-between">
                <dt class="text-sm">Next-day Shipping </dt>
                <dd class="text-sm font-medium text-gray-900">
                  Free
                </dd>
              </div>
              <div class="flex items-center justify-between">
                <dt class="text-sm">VAT (20%)</dt>
                <dd class="text-sm font-medium text-gray-900">
                  {{ toCurrency(getVAT) }}
                </dd>
              </div>
              <div class="flex items-center justify-between border-t border-gray-200 pt-6">
                <dt class="text-base font-medium">Total</dt>
                <dd class="text-base font-medium text-gray-900">
                  {{ toCurrency(getTotal) }}
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div>
          <button
              @click="addToBasket"
              class="flex gap-x-2 w-full flex-1 items-center justify-center rounded-md border border-transparent bg-intelli-green py-3 px-8 text-base font-medium text-white hover:bg-intelli-green-dark focus:outline-none focus:ring-2 focus:ring-intelli-green focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full">

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
            </svg>
            <span>Add to Basket</span>
          </button>

        </div>
      </div>
    </div>
    <div>
      <div class="p-6 max-w-sm mx-auto">
        <img :src="'/images/products/' + part_no + '.png'" :alt="title" :title="title" />


        <div class="text-lg text-center font-bold mt-5">
          RRP: {{ toCurrency(rrp) }} <small class="text-xs">incl. VAT</small>
        </div>

        <ul role="list" class="w-full text-sm mt-5">
          <li class="py-2 w-full" :key="'list-item-' + k" v-for="(i, k) of checklist">
            <div class="flex gap-x-2">
              <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" stroke-width="1.5" fill="currentColor" class="w-6 h-6 flex-grow-0 text-intelli-blue">
                <path stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
              </svg>
              <span>
                  {{ i }}
                   </span>
            </div>
          </li>

        </ul>


        <button
            @click="() => $router.push('/basket')"
            class="flex mt-3 w-full flex-1 items-center justify-center rounded-md border border-transparent bg-intelli-blue py-3 px-8 text-base font-medium text-white hover:bg-intelli-blue-dark focus:outline-none focus:ring-2 focus:ring-intelli-blue focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full">
          Checkout
        </button>

      </div>
    </div>
  </div>
</template>

<script>
import ProductOptionsComponent from "@/components/ProductOptionsComponent";
import {useAuthStore} from "@/store";
import {useMeta} from "vue-meta";
import {notify} from "@/utils/notification";

import {getById} from "@/utils/product-catalogue";

export default {
  name: "ChargerComponent",
  props: {
    price: {
      type: Number,
      default(){
        return 0
      }
    },
    productId: {
      type: String,
      default(){
        return ''
      }
    },
    sku: {
      type: String,
      default(){
        return null
      }
    },
    uan: {
      type: String,
      default(){
        return null
      }
    },
    rrp: {
      type: Number,
      default(){
        return 0
      }
    }
  },
  components: {ProductOptionsComponent},
  setup() {
    const store = useAuthStore();
    useMeta({
      title: 'Create new Order'
    })
    return {
      setItemToBasket: store.setItemToBasket
    }
  },
  data() {
    return getById(this.productId);
  },
  computed: {
    checklist(){
      return getById(this.productId).checklist ? getById(this.productId).checklist : [];
    },
    findSelectedColor(){
      let find = this.color.find((x) => {
        return x.value === this.selected.color;
      });
      return find ? find.label : "";
    },
    getVAT(){
      return this.price / 100 * 20;
    },
    getTotal(){
      return this.getVAT + this.price;
    }
  },
  methods: {
    toCurrency(value, locale, currency) {
      locale = locale || 'en-GB';
      currency = currency || 'GBP';
      return new Intl.NumberFormat(locale, { style: 'currency', currency,
        minimumFractionDigits: 2
      }).format(value);
    },
    addToBasket(){
      this.setItemToBasket(this.part_no);
      setTimeout(() => {
        notify({
          title: 'Added to Basket',
          message: this.title + ' added to basket'
        });
      }, 10)
    }
  }
}
</script>

<style scoped>

</style>