import {useStorage} from '@vueuse/core'
import {defineStore} from "pinia";

const state = useStorage('intelli-ev-portal:auth', {
    access_token: null,
    refresh_token: null,
    api: process.env.NODE_ENV === 'production' ? 'https://portal-api.intelliev.uk' : 'http://localhost',
    showMenu: false,
    user: {
        id: null,
        contact_name: null,
        company_name: null,
        email: null,
        is_admin: false
    },
    breadcrumbs: [],
    basket: {}
});

export const useAuthStore = defineStore({
    id: 'auth', 
    state: () => (state),
    getters: {
        basePricing(){
          return this.user && this.user.base_pricing && Array.isArray(this.user.base_pricing) ? this.user.base_pricing : [];
        },
        basketObject() {
            let object = [];
            for (let o in this.basket) {
                object.push({
                    id: o,
                    title: this.basket[o].title,
                    price: this.basket[o].price,
                    subtotal: this.basket[o].subtotal,
                    quantity: this.basket[o].quantity
                })
            }
            return object;
        },
        totalBasketItems() {
            let total = 0;
            for (let o in this.basket) {
                total += this.basket[o].quantity;
            }
            return total;
        },
        apiPath(){
            return this.api;
        },
        certificateUrl(){
            return this.api + '/api/v1/auth/user/certificate?token=' + this.refresh_token;
        }
    },
    actions: {
        toggleMobileMenu() {
            this.showMenu = !this.showMenu;
        },
        getAccessToken() {
            return this.access_token
        },
        isMenuOpen(){
            return this.showMenu;
        },
        getRefreshToken() {
            return this.refresh_token;
        },
        getFirstName(){
            return this.user.contact && this.user.contact.first_name ? this.user.contact.first_name.charAt(0).toUpperCase() + this.user.contact.first_name.slice(1) : "";
        },
        setAccessToken(access_token) {
            this.access_token = access_token;
        },
        setMenuStatus(bool) {
            this.showMenu = bool;
        },
        setRefreshToken(refresh_token) {
            this.refresh_token = refresh_token;
        },
        isAuthenticated() {
            return this.access_token !== null;
        },
        hasBillingAccount() {
            return this.user.has_account ? this.user.has_account : false;
        },
        emailVerified() {
            return this.user.email_confirmed ? this.user.email_confirmed : false;
        },
        isAdmin() {
            return this.user.is_admin ? this.user.is_admin : false;
        },
        getUsername() {
            if (this.user && this.user.account) {
                return [this.user.account.first_name, this.user.account.last_name].filter((x) => {
                    return x && x.length > 0
                }).join(' ')
            }
            return null;
        },
        getEmail() {
            if (this.user && this.user.account) {
                return this.user.account.email;
            }
            return null;
        },
        reset() {
            this.access_token = null;
            this.refresh_token = null;
            this.user = {
                id: null,
                contact_name: null,
                company_name: null,
                email: null,
                is_admin: false
            };
            this.breadcrumbs = [];
            this.basket = {};
        },
        getBreadcrumbs() {
            return [
                {
                    title: 'Intelli EV Portal',
                    path: '/'
                }
            ].concat(this.breadcrumbs)
        },
        setUser(payload) {
            this.user = payload
        },
        setItemToBasket(productId) {
            let key = Object.prototype.hasOwnProperty.call(this.basket, productId);
            if (!key) {
                this.basket[productId] = {
                    id: productId,
                    price: 0,
                    subtotal: 0,
                    quantity: 0,
                    max_quantity: 0
                }
            }
            this.basket[productId].quantity++;
        },
        setBasketObject(payload) {
            this.basket = payload;
        },
        emptyBasket() {
            this.basket = {};
        },
        setRemoveBasketItem(productId) {
            let key = Object.prototype.hasOwnProperty.call(this.basket, productId);
            if (key) {
                delete this.basket[productId];
            }
        },
        setBasketQuantity(productId, quantity) {
            let key = Object.prototype.hasOwnProperty.call(this.basket, productId);
            if (key) {
                console.log('key', productId, 'quantity', quantity);
                let basket = JSON.parse(JSON.stringify(this.basket));
                basket[productId].quantity = quantity;
                console.log(basket)
                this.basket = basket;
            }
            console.log(this.basket);
        }
    }
});
