<template>
  <page-layout>
    <template v-slot:content>

      <page-header>
        <template v-slot:icon>
          <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"></path>          </svg>
        </template>
        <template v-slot:title>
          My Account
        </template>
      </page-header>


      <div v-if="loading">

        <spin-loader/>

      </div>
      <div v-else class="my-10">


        <div class="mt-10 sm:mt-0">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-base font-semibold leading-6 text-gray-900">
                  Contact Details
                </h3>
              </div>
            </div>
            <div class="mt-5 md:col-span-2 md:mt-0">

              <div class="overflow-hidden shadow sm:rounded-md">
                <div class="bg-white px-4 py-5 sm:p-6">
                  <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6 lg:col-span-3">
                      <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">First name<span
                          class="ml-1 text-red-700">*</span>
                      </label>
                      <input type="text"
                             :disabled="contact_details.running"
                             :class="{
                          'bg-gray-50 cursor-not-allowed': contact_details.running,
                          'border focus:ring-red-500 border-red-500': contact_details.errors['contact_details.first_name'] && contact_details.errors['contact_details.first_name'].length > 0
                               }"
                             v-model="contact_details.first_name" name="first-name"
                             id="first-name" autocomplete="first-name"
                             class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                      <div class="text-xs text-red-700 mt-2"
                           v-if="contact_details.errors['contact_details.first_name'] && contact_details.errors['contact_details.first_name'].length > 0">
                        {{ contact_details.errors['contact_details.first_name'][0] }}
                      </div>
                    </div>

                    <div class="col-span-6 lg:col-span-3">
                      <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900">Last name <span
                          class="ml-1 text-red-700">*</span></label>
                      <input type="text"
                             :disabled="contact_details.running"
                             :class="{
                          'bg-gray-50 cursor-not-allowed': contact_details.running,
    'border focus:ring-red-500 border-red-500': contact_details.errors['contact_details.last_name'] && contact_details.errors['contact_details.last_name'].length > 0
                               }"
                             v-model="contact_details.last_name" name="last-name"
                             id="last-name" autocomplete="family-name"
                             class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                      <div class="text-xs text-red-700 mt-2"
                           v-if="contact_details.errors['contact_details.last_name'] && contact_details.errors['contact_details.last_name'].length > 0">
                        {{ contact_details.errors['contact_details.last_name'][0] }}
                      </div>
                    </div>


                    <div class="col-span-6">
                      <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address<span
                          class="ml-1 text-red-700">*</span></label>
                      <input type="email"
                             :disabled="contact_details.running"
                             :class="{
                          'bg-gray-50 cursor-not-allowed': contact_details.running,
           'border focus:ring-red-500 border-red-500': contact_details.errors['contact_details.email'] && contact_details.errors['contact_details.email'].length > 0
                               }"
                             v-model="contact_details.email" name="email"
                             id="email" autocomplete="email"
                             class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                      <div class="text-xs text-red-700 mt-2"
                           v-if="contact_details.errors['contact_details.email'] && contact_details.errors['contact_details.email'].length > 0">
                        {{ contact_details.errors['contact_details.email'][0] }}
                      </div>

                    </div>

                    <div class="col-span-6">
                      <label for="tel-number" class="block text-sm font-medium leading-6 text-gray-900">Telephone number<span
                          class="ml-1 text-red-700">*</span></label>
                      <input type="tel"
                             :disabled="contact_details.running"
                             :class="{
                          'bg-gray-50 cursor-not-allowed': contact_details.running,
     'border focus:ring-red-500 border-red-500': contact_details.errors['contact_details.tel'] && contact_details.errors['contact_details.tel'].length > 0
                               }"
                             v-model="contact_details.tel"
                             name="tel-number"
                             id="tel-number" autocomplete="tel"
                             class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                      <div class="text-xs text-red-700 mt-2"
                           v-if="contact_details.errors['contact_details.tel'] && contact_details.errors['contact_details.tel'].length > 0">
                        {{ contact_details.errors['contact_details.tel'][0] }}
                      </div>
                    </div>


                  </div>
                </div>
                <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <el-button
                      @click="updateContactDetails()"
                      :disabled="contact_details.running"
                      :loading="contact_details.running"
                      color="green">
                    Update Contact Details
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="hidden sm:block" aria-hidden="true">
          <div class="py-5">
            <div class="border-t border-gray-200"></div>
          </div>
        </div>

        <div>
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-base font-semibold leading-6 text-gray-900">
                  Company Details
                </h3>
                <!--
                <p class="mt-1 text-sm text-gray-600">This information will be displayed publicly so be careful what you
                  share - .</p> -->
              </div>
            </div>
            <div class="mt-5 md:col-span-2 md:mt-0">
              <div class="shadow sm:overflow-hidden sm:rounded-md">
                <div class="space-y-6 bg-white px-4 py-5 sm:p-6">


                  <div class="grid grid-cols-6 gap-6">

                    <div class="col-span-6">
                      <label for="company-name" class="block text-sm font-medium leading-6 text-gray-900">Company
                        name<span class="ml-1 text-red-700">*</span>
                      </label>
                      <input type="text"
                             :disabled="company_details.running"
                             :class="{
                          'bg-gray-50 cursor-not-allowed': company_details.running,
                          'border focus:ring-red-500 border-red-500': company_details.errors['company_details.name'] &&
                          company_details.errors['company_details.name'].length > 0
                               }"
                             v-model="company_details.name"
                             name="company-name"
                             id="company-name"
                             autocomplete="organization"
                             class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                      <div class="text-xs text-red-700 mt-2"
                           v-if="company_details.errors['company_details.name'] && company_details.errors['company_details.name'].length > 0">
                        {{ company_details.errors['company_details.name'][0] }}
                      </div>
                    </div>

                    <div class="col-span-6">
                      <label for="trading-name" class="block text-sm font-medium leading-6 text-gray-900">
                        Trading name (If different to company name)
                      </label>
                      <input type="text"
                             name="trading-name"
                             :disabled="company_details.running"
                             :class="{
                          'bg-gray-50 cursor-not-allowed': company_details.running
                          }"
                             v-model="company_details.trading_name"
                             id="trading-name"
                             autocomplete="trading-name"
                             class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                    </div>

                    <div class="col-span-6 lg:col-span-3">
                      <label for="company-number" class="block text-sm font-medium leading-6 text-gray-900">
                        Company number
                      </label>
                      <input type="text"
                             name="company-number"
                             id="company-number"
                             :disabled="company_details.running"
                             :class="{
                          'bg-gray-50 cursor-not-allowed': company_details.running
                          }"
                             v-model="company_details.company_number"
                             autocomplete="company-number"
                             class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                    </div>

                    <div class="col-span-6 lg:col-span-3">
                      <label for="website" class="block text-sm font-medium leading-6 text-gray-900">Website
                      </label>
                      <input type="text"
                             :disabled="company_details.running"
                             :class="{
                          'bg-gray-50 cursor-not-allowed': company_details.running,
                          'border focus:ring-red-500 border-red-500': company_details.errors['company_details.website'] &&
                          company_details.errors['company_details.website'].length > 0
                               }"
                             v-model="company_details.website"
                             name="website"
                             id="website"
                             autocomplete="url"
                             class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                      <div class="text-xs text-red-700 mt-2"
                           v-if="company_details.errors['company_details.website'] && company_details.errors['company_details.website'].length > 0">
                        {{ company_details.errors['company_details.website'][0] }}
                      </div>
                    </div>


                    <div class="col-span-6">
                      <label for="address-line-one" class="block text-sm font-medium leading-6 text-gray-900">Address
                        Line One<span class="ml-1 text-red-700">*</span>
                      </label>
                      <input type="text"
                             :disabled="company_details.running"
                             :class="{
                          'bg-gray-50 cursor-not-allowed': company_details.running,
                          'border focus:ring-red-500 border-red-500': company_details.errors['company_details.address_line_one'] &&
                          company_details.errors['company_details.address_line_one'].length > 0
                               }"
                             v-model="company_details.address_line_one"
                             name="address-line-one"
                             id="address-line-one"
                             autocomplete="address-line1"
                             class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                      <div class="text-xs text-red-700 mt-2"
                           v-if="company_details.errors['company_details.address_line_one'] && company_details.errors['company_details.address_line_one'].length > 0">
                        {{ company_details.errors['company_details.address_line_one'][0] }}
                      </div>
                    </div>

                    <div class="col-span-6">
                      <label for="address-line-two" class="block text-sm font-medium leading-6 text-gray-900">
                        Address Line Two
                      </label>
                      <input
                          :disabled="company_details.running"
                          :class="{
                          'bg-gray-50 cursor-not-allowed': company_details.running
                          }"
                          v-model="company_details.address_line_two"
                          type="text" name="address-line-two" id="address-line-two" autocomplete="address-line2"
                             class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                    </div>

                    <div class="col-span-6 lg:col-span-3">
                      <label for="town-city" class="block text-sm font-medium leading-6 text-gray-900">Town/City<span
                          class="ml-1 text-red-700">*</span>
                      </label>
                      <input type="text"
                             :disabled="company_details.running"
                             :class="{
                          'bg-gray-50 cursor-not-allowed': company_details.running,
                          'border focus:ring-red-500 border-red-500': company_details.errors['company_details.town_city'] &&
                          company_details.errors['company_details.town_city'].length > 0
                               }"
                             v-model="company_details.town_city"
                             name="town-city"
                             id="town-city"
                             autocomplete="address-line3"
                             class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                      <div class="text-xs text-red-700 mt-2"
                           v-if="company_details.errors['company_details.town_city'] && company_details.errors['company_details.town_city'].length > 0">
                        {{ company_details.errors['company_details.town_city'][0] }}
                      </div>
                    </div>

                    <div class="col-span-6 lg:col-span-3">
                      <label for="county-region" class="block text-sm font-medium leading-6 text-gray-900">County/Region<span
                          class="ml-1 text-red-700">*</span>
                      </label>
                      <input type="text"
                             :disabled="company_details.running"
                             :class="{
                          'bg-gray-50 cursor-not-allowed': company_details.running,
                          'border focus:ring-red-500 border-red-500': company_details.errors['company_details.county_region'] &&
                          company_details.errors['company_details.county_region'].length > 0
                               }"
                             v-model="company_details.county_region"
                             name="county-region"
                             id="county-region"
                             autocomplete="address-line4"
                             class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                      <div class="text-xs text-red-700 mt-2"
                           v-if="company_details.errors['company_details.county_region'] && company_details.errors['company_details.county_region'].length > 0">
                        {{ company_details.errors['company_details.county_region'][0] }}
                      </div>
                    </div>

                    <div class="col-span-6 lg:col-span-3">
                      <label for="postcode" class="block text-sm font-medium leading-6 text-gray-900">Postcode<span
                          class="ml-1 text-red-700">*</span>
                      </label>
                      <input type="text"
                             :disabled="company_details.running"
                             :class="{
                          'bg-gray-50 cursor-not-allowed': company_details.running,
                          'border focus:ring-red-500 border-red-500': company_details.errors['company_details.postcode'] &&
                          company_details.errors['company_details.postcode'].length > 0
                               }"
                             v-model="company_details.postcode"
                             name="postcode"
                             id="postcode"
                             autocomplete="postal-code"
                             class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                      <div class="text-xs text-red-700 mt-2"
                           v-if="company_details.errors['company_details.postcode'] && company_details.errors['company_details.postcode'].length > 0">
                        {{ company_details.errors['company_details.postcode'][0] }}
                      </div>
                    </div>


                  </div>


                </div>
                <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <el-button
                      @click="updateCompanyDetails()"
                      :disabled="company_details.running"
                      :loading="company_details.running"
                      color="green">
                    Update Company Details
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="hidden sm:block" aria-hidden="true">
          <div class="py-5">
            <div class="border-t border-gray-200"></div>
          </div>
        </div>

<!--
        <div>
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-base font-semibold leading-6 text-gray-900">
                  Certificates
                </h3>
                <p class="mt-1 text-sm text-gray-600">This information will be displayed publicly so be careful what you
                  share - .</p>
              </div>
            </div>
            <div class="mt-5 md:col-span-2 md:mt-0">
              <div class="shadow sm:overflow-hidden sm:rounded-md">
                <div class="space-y-6 bg-white px-4 py-5 sm:p-6">


                </div>
                <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <button type="submit"
                          class="inline-flex justify-center rounded-md bg-green-600 py-3 px-4 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div class="hidden sm:block" aria-hidden="true">
          <div class="py-5">
            <div class="border-t border-gray-200"></div>
          </div>
        </div>
        -->
        <div class="mt-10 sm:mt-0">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-base font-semibold leading-6 text-gray-900">
                  Security
                </h3>
              </div>
            </div>
            <div class="mt-5 md:col-span-2 md:mt-0">
              <div class="overflow-hidden shadow sm:rounded-md">
                <div class="bg-white px-4 py-5 sm:p-6">
                  <div class="grid grid-cols-6 gap-6">


                    <div class="col-span-6">
                      <label for="current-password" class="block text-sm font-medium leading-6 text-gray-900">Current
                        Password<span class="ml-1 text-red-700">*</span>
                      </label>
                      <input type="password"
                             :class="{
                          'border focus:ring-red-500 border-red-500': password.errors['password.current_password'] &&
                          password.errors['password.current_password'].length > 0,
                                                    'bg-gray-100 cursor-not-allowed': password.running
                               }"
                             v-model="password.current_password"
                             :disabled="password.running"
                             name="current-password"
                             id="current-password"
                             autocomplete="off"
                             class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                      <div class="text-xs text-red-700 mt-2"
                           v-if="password.errors['password.current_password'] && password.errors['password.current_password'].length > 0">
                        {{ password.errors['password.current_password'][0] }}
                      </div>
                    </div>


                    <div class="col-span-6 lg:col-span-3">
                      <label for="new-password" class="block text-sm font-medium leading-6 text-gray-900">
                        New Password<span class="ml-1 text-red-700">*</span>
                      </label>
                      <input type="password"
                             :class="{
                          'border focus:ring-red-500 border-red-500': password.errors['password.new_password'] &&
                          password.errors['password.new_password'].length > 0,
                          'bg-gray-100 cursor-not-allowed': password.running
                               }"
                             v-model="password.new_password"
                             name="new-password"
                             id="new-password"
                             :disabled="password.running"
                             autocomplete="new-password"
                             class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                      <div class="text-xs text-red-700 mt-2"
                           v-if="password.errors['password.new_password'] && password.errors['password.new_password'].length > 0">
                        {{ password.errors['password.new_password'][0] }}
                      </div>
                    </div>

                    <div class="col-span-6 lg:col-span-3">
                      <label for="new-password-repeat" class="block text-sm font-medium leading-6 text-gray-900">
                        Repeat Password<span class="ml-1 text-red-700">*</span>
                      </label>
                      <input type="password"
                             :class="{
                          'border focus:ring-red-500 border-red-500': password.errors['password.new_password_repeat'] &&
                          password.errors['password.new_password'].length > 0,
                                                    'bg-gray-100 cursor-not-allowed': password.running
                               }"
                             v-model="password.new_password_repeat"
                             :disabled="password.running"
                             name="new-password-repeat"
                             id="new-password-repeat"
                             autocomplete="new-password-repeat"
                             class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                      <div class="text-xs text-red-700 mt-2"
                           v-if="password.errors['password.new_password_repeat'] && password.errors['password.new_password_repeat'].length > 0">
                        {{ password.errors['password.new_password_repeat'][0] }}
                      </div>
                    </div>


                  </div>
                </div>
                <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <el-button
                      @click="updatePassword()"
                      :disabled="password.running"
                      :loading="password.running"
                      color="green">
                    Change Password
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="hidden sm:block" aria-hidden="true">
          <div class="py-5">
            <div class="border-t border-gray-200"></div>
          </div>
        </div>

        <div class="mt-10 sm:mt-0">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-base font-semibold leading-6 text-gray-900">
                  Delete Account
                </h3>
              </div>
            </div>
            <div class="mt-5 md:col-span-2 md:mt-0">
              <div class="overflow-hidden shadow sm:rounded-md">
                <div class="bg-white px-4 py-5 sm:p-6">
                  <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6">
                      <div class="bg-red-100 text-red-800 text-sm p-3 rounded">
                        Warning! Deleting your account cannot be undone. All data attached to your account will be removed.
                      </div>
                    </div>
                    <div class="col-span-6">
                      <label for="delete-current-password" class="block text-sm font-medium leading-6 text-gray-900">
                         Password<span class="ml-1 text-red-700">*</span>
                      </label>
                      <input type="password"
                             :class="{
                          'border focus:ring-red-500 border-red-500': delete_account.errors['delete_account.current_password'] &&
                          delete_account.errors['delete_account.current_password'].length > 0,
                          'bg-gray-100 cursor-not-allowed': delete_account.running
                               }"
                             v-model="delete_account.current_password"
                             :disabled="delete_account.running"
                             name="delete-current-password"
                             id="delete-current-password"
                             autocomplete="off"
                             class="mt-2 focus:ring-green-600 border-gray-300 block w-full rounded-md border px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6">
                      <div class="text-xs text-red-700 mt-2"
                           v-if="delete_account.errors['delete_account.current_password'] && delete_account.errors['delete_account.current_password'].length > 0">
                        {{ delete_account.errors['delete_account.current_password'][0] }}
                      </div>
                    </div>
                    <div class="col-span-6">
                      <label for="delete-current-confirm" class="block text-sm font-medium leading-6 text-gray-900">
                        Please type "delete account" below<span class="ml-1 text-red-700">*</span>
                      </label>
                      <input type="text"
                             :class="{
                          'border focus:ring-red-500 border-red-500': delete_account.errors['delete_account.confirmation'] &&
                          delete_account.errors['delete_account.confirmation'].length > 0,
                         'bg-gray-100 cursor-not-allowed': delete_account.running
                               }"
                             v-model="delete_account.confirmation"
                             :disabled="delete_account.running"
                             name="delete-current-confirm" id="delete-current-confirm" autocomplete="off"
                             class="mt-2 block w-full rounded-md border-0 px-4 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6">
                      <div class="text-xs text-red-700 mt-2"
                           v-if="delete_account.errors['delete_account.confirmation'] && delete_account.errors['delete_account.confirmation'].length > 0">
                        {{ delete_account.errors['delete_account.confirmation'][0] }}
                      </div>
                    </div>

                  </div>
                </div>
                <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <el-button
                      @click="confirmDeleteAccount"
                      :disabled="delete_account.running" :loading="delete_account.running">
                    Delete Account
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

    </template>
  </page-layout>
</template>

<script>
import PageLayout from "@/Layout/PageLayout";
import PageHeader from "@/components/PageHeader";

import {email, required, minLength, helpers, url} from '@vuelidate/validators'
import {useVuelidate} from '@vuelidate/core'
import {isValid} from "postcode";
import SpinLoader from "@/components/SpinLoader";
import { notify, handleServerError } from "@/utils/notification";
import {useMeta} from "vue-meta";
import ElButton from "@/components/ElButton";

export default {
  name: "UserAccount",
  components: {ElButton, SpinLoader, PageHeader, PageLayout},
  setup(){
    useMeta({
      title: 'Your Account'
    })
    return {
      v$: useVuelidate()
    }
  },
  validations() {
    return {
      contact_details: {
        first_name: {
          required: helpers.withMessage('A first name is required', required),
          minLength: minLength(3)
        },
        last_name: {
          required: helpers.withMessage('A last name is required', required),
          minLength: minLength(3)
        },
        email: {
          required: helpers.withMessage('An email address is required', required),
          email: helpers.withMessage('A valid email address is required', email)
        },
        tel: {
          required: helpers.withMessage('A UK Phone number is required', required),
          phoneNumber: helpers.withMessage('Please enter a valid UK Phone number', (value) => {
            value = value.replace(/\s/g, "");
            let regex = /^\+?(?:\d\s?){10,12}$/i;
            return regex.test(value);
          })
        },
      },
      company_details: {
        name: {
          required: helpers.withMessage('A company name is required', required),
        },
        address_line_one: {
          required: helpers.withMessage('Address line one is required', required),
        },
        town_city: {
          required: helpers.withMessage('Town/City is required', required),
        },
        county_region: {
          required: helpers.withMessage('County/Region is required', required),
        },
        website: {
          url: helpers.withMessage('A valid website URL starting with http/https is required', url),
        },
        postcode: {
          required: helpers.withMessage('Postcode is required', required),
          validPostcode: helpers.withMessage('A valid postcode is required', isValid)
        },
      },
      password: {
        current_password: {
          required: helpers.withMessage('Current password is required', required)
        },
        new_password: {
          required: helpers.withMessage('New password is required', required),
          minLength: minLength(6)
        },
        new_password_repeat: {
          required: helpers.withMessage('Repeat new password is required', required),
          exactMatch: helpers.withMessage('Please enter a valid UK Phone number', () => {
            return this.passwordsMatch;
          })
        },
      },
      delete_account: {
        current_password: {
          required: helpers.withMessage('Current password is required', required)
        },
        confirmation: {
          required: helpers.withMessage('Confirmation message is required', required),
          equals: helpers.withMessage('You must enter "delete account" ', (value) => {
            return value === 'delete account'
          })
        },
      },
    }
  },
  mounted() {
    this.fetch();
  },
  data: () => ({
    loading: true,
    contact_details: {
      first_name: null,
      last_name: null,
      email: null,
      tel: null,
      running: false,
      errors: {}
    },
    company_details: {
      name: null,
      company_number: null,
      trading_name: null,
      website: null,
      address_line_one: null,
      address_line_two: null,
      town_city: null,
      county_region: null,
      postcode: null,
      running: false,
      errors: {}
    },
    password: {
      current_password: null,
      new_password: null,
      new_password_repeat: null,
      running: false,
      errors: {}
    },
    delete_account: {
      current_password: null,
      confirmation: null,
      running: false,
      errors: {}
    },
  }),
  computed: {
    passwordsMatch() {
      return this.password.new_password === this.password.new_password_repeat;
    }
  },
  methods: {
    async validateFields(fields, prop) {
      const vm = this;
      vm.contact_details.errors = {};
      return await vm.v$.$validate().then(() => {

        for (let x of fields) {
          let obj = Object.prototype.hasOwnProperty.call(vm[prop].errors, x);

          let find = vm.v$.$errors.filter((y) => {
            return y.$propertyPath === x;
          })
          if (find.length > 0) {
            if (!obj) {
              vm[prop].errors[x] = [];
            }
          }
          for (let e of find) {
            vm[prop].errors[x].push(e.$message);
          }
        }

        return !Object.keys(vm[prop].errors).length

      })
    },

    async validateDeleteAccountFields(){
      const vm = this;
      vm.delete_account.errors = {};
      return await vm.validateFields([
        'delete_account.current_password', 'delete_account.confirmation',
      ], 'delete_account');
    },
    async validatePasswordReset() {
      const vm = this;
      vm.password.errors = {};
      return await vm.validateFields([
        'password.current_password', 'password.new_password',
        'password.new_password_repeat'
      ], 'password');
    },
    async validateContactDetails() {
      const vm = this;
      vm.contact_details.errors = {};
      return await vm.validateFields([
        'contact_details.first_name', 'contact_details.last_name',
        'contact_details.email', 'contact_details.tel'
      ], 'contact_details');
    },
    async validateCompanyDetails() {
      const vm = this;
      vm.company_details.errors = {};
      return await vm.validateFields([
        'company_details.name', 'company_details.address_line_one',
        'company_details.town_city', 'company_details.county_region',
        'company_details.postcode', 'company_details.website'
      ], 'company_details');
    },
    async confirmDeleteAccount(){

      let valid = await this.validateDeleteAccountFields();
      this.delete_account.running = true;
      if (!valid) {
        this.delete_account.running = false;
        return;
      }

    },
    async updateContactDetails() {
      let valid = await this.validateContactDetails();
      this.contact_details.running = true;
      if (!valid) {
        this.contact_details.running = false;
        return;
      }

      this.$http.post('/api/v1/auth/user/contact-details', this.contact_details)
          .then(() => {
            this.contact_details.running = false;
            notify({
              title: 'Contact Details Updated Successfully'
            });
          })
          .catch((response) => {
            this.contact_details.running = false;
            handleServerError(response);
          })

    },
    async updatePassword() {
      let valid = await this.validatePasswordReset();
      this.password.running = true;
      if (!valid) {
        this.password.running = false;
        return;
      }

      this.$http.post('/api/v1/auth/user/password', this.password)
          .then(() => {
            this.password.new_password = "";
            this.password.current_password = "";
            this.password.new_password_repeat = "";
            this.password.running = false;
            notify({
              title: 'Password Updated Successfully'
            });
          })
          .catch((response) => {
            this.password.running = false;
            handleServerError(response);
          })

    },
    async updateCompanyDetails() {
      let valid = await this.validateCompanyDetails();
      this.company_details.running = true;
      if (!valid) {
        this.company_details.running = false;
        return;
      }
      this.$http.post('/api/v1/auth/user/company-details', this.company_details)
          .then(() => {
            this.company_details.running = false;
            notify({
              title: 'Company Details Updated Successfully'
            });
          })
          .catch((response) => {
            this.company_details.running = false;
            handleServerError(response);
          })

    },
    fetch() {
      const vm = this;
      vm.loading = true;
      vm.$http.get('/api/v1/auth/user')
          .then((response) => {

            vm.contact_details.first_name = response.data.data.contact.first_name;
            vm.contact_details.last_name = response.data.data.contact.last_name;
            vm.contact_details.email = response.data.data.contact.email;
            vm.contact_details.email_confirmed = response.data.data.contact.email_confirmed;
            vm.contact_details.tel = response.data.data.contact.phone_number;

            vm.company_details.company_number = response.data.data.company.company_number;
            vm.company_details.name = response.data.data.company.company_name;
            vm.company_details.website = response.data.data.company.website;
            vm.company_details.trading_name = response.data.data.company.trading_name;
            vm.company_details.address_line_one = response.data.data.company.address_line_one;
            vm.company_details.address_line_two = response.data.data.company.address_line_two;
            vm.company_details.town_city = response.data.data.company.address_city;
            vm.company_details.county_region = response.data.data.company.address_state;
            vm.company_details.postcode = response.data.data.company.address_post_code;

            vm.loading = false;
          }).catch(() => {
        vm.$router.push('/')
      })
    }
  }
}
</script>