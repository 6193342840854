<template>
  <page-layout>
    <template v-slot:content>

      <page-header>
        <template v-slot:title>
          {{ getGreeting }}, {{ firstName }}
        </template>
      </page-header>

      <div class="my-6 prose">
        <p>Welcome to your Intelli EV Installer Portal Dashboard.</p>
        <p>Here you can find everything you need from documentation to placing orders.</p>
        <p>If you have any question please dont hesitate to contact us on <strong>0808 169 7702</strong>.</p>
      </div>

      <div class="flex flex-wrap gap-4">
        <router-link to="/new-order" class="rounded-md text-center w-full md:w-auto inline-block border border-transparent bg-intelli-green py-3 px-8 text-base font-medium text-white hover:bg-intelli-green-dark focus:outline-none focus:ring-2 focus:ring-intelli-green focus:ring-offset-2 focus:ring-offset-gray-50">Place an Order</router-link>
        <a href="https://www.intelliev.uk/contact" target="_blank" class="rounded-md text-center w-full md:w-auto inline-block border-2 border-intelli-green bg-transparent py-3 px-8 text-base font-medium text-intelli-green hover:text-white hover:bg-intelli-green focus:outline-none focus:ring-2 focus:ring-intelli-green focus:ring-offset-2 focus:ring-offset-gray-50">Contact Us</a>

      </div>

    </template>
  </page-layout>
</template>

<script>
import PageLayout from "@/Layout/PageLayout";
import PageHeader from "@/components/PageHeader";
import {useMeta} from "vue-meta";
import {useAuthStore} from "@/store";

export default {
  name: "UserDashboard",
  components: {PageHeader, PageLayout},
  setup() {
    const store = useAuthStore();
    useMeta({
      title: 'Dashboard'
    });
    return {
      firstName: store.getFirstName()
    }
  },
  data() {
    return {}
  },
  computed: {
    getGreeting(){
      let curHr = (new Date()).getHours();
      if (curHr < 12) {
        return 'Good Morning';
      } else if (curHr < 18) {
        return 'Good Afternoon';
      } else {
        return 'Good Evening';
      }
    }
  }
}
</script>