import * as VueRouter from 'vue-router'


import {useAuthStore} from "@/store";
import Login from "@/pages/Login";
import OrderHistory from "@/pages/OrderHistory";
import UserAccount from "@/pages/UserAccount";
import CreateNewOrder from "@/pages/CreateNewOrder";

import Marketing from "@/pages/MarketingTools";
import YourBasket from "@/pages/YourBasket";
import UserDashboard from "@/pages/UserDashboard";
import TermsAndConditions from "@/pages/TermsAndConditions";
import TrainingGuidesVideos from "@/pages/TrainingGuidesVideos";
import InstallerDocumentation from "@/pages/InstallerDocumentation";
import PageNotFound from "@/pages/PageNotFound";
import PasswordReset from "@/pages/PasswordReset";
import BulletinBoard from "@/pages/BulletinBoard";
import AdminCreateUser from "@/pages/Admin/AdminCreateUser";

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/password-reset',
        name: 'PasswordReset',
        component: PasswordReset
    },
    {
        path: '/',
        name: 'UserDashboard',
        component: UserDashboard,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/order-history',
        name: 'OrderHistory',
        component: OrderHistory,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/my-account',
        name: 'UserAccount',
        component: UserAccount,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/bulletin-board',
        name: 'BulletinBoard',
        component: BulletinBoard,
        meta: {
            requiresAuth: true
        }
    },    {
        path: '/training',
        name: 'TrainingGuidesVideos',
        component: TrainingGuidesVideos,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/documentation',
        name: 'InstallerDocumentation',
        component: InstallerDocumentation,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/terms-and-conditions',
        name: 'TermsAndConditions',
        component: TermsAndConditions,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/basket',
        name: 'YourBasket',
        component: YourBasket,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/marketing',
        name: 'Marketing',
        component: Marketing,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/new-order',
        name: 'CreateNewOrder',
        component: CreateNewOrder,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/admin/create-user',
        name: 'CreateNewUser',
        component: AdminCreateUser,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'PageNotFound',
        component: PageNotFound,
        meta: {
            requiresAuth: false
        }
    }
]
const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    base: process.env.BASE_URL,
    routes
});


router.beforeEach(async (to) => {

    // redirect to login page if not logged in and trying to access a restricted page
    const authRequired = to.meta.requiresAuth && to.meta.requiresAuth === true;
    const requiresAdmin = to.meta.requiresAdmin && to.meta.requiresAdmin === true;
    const authStore = useAuthStore();

    if(authStore.isMenuOpen()){
        authStore.setMenuStatus(false);
    }
    if (requiresAdmin && !authStore.isAdmin()) {
        return '/';
    }

    if (authRequired && !authStore.isAuthenticated()) {
        return '/login?redirect=' +  to.fullPath;
    }
});

export default router;