export function getAll() {
    return {
        accessories: [
            {
                title: 'EVS II Load Balancing',
                items: [
                    {
                        part_no: 'EVSII-CT-SINGLE-PHASE',
                        title: 'Single Phase CT',
                        price: 18,
                        available: false
                    },
                    {
                        part_no: 'EVSII-CT-THREE-PHASE',
                        title: 'Three Phase CT',
                        price: 38,
                        available: true
                    }
                ]
            },
            {
                title: 'EVS II Solar',
                items: [
                    {
                        part_no: 'EVSII-CT-SOLAR-SINGLE-PHASE',
                        title: 'Single Phase CT + Single Phase Energy Meter',
                        price: 59,
                        available: false
                    },
                    {
                        part_no: 'EVSII-CT-SOLAR-THREE-PHASE',
                        title: 'Three Phase CT + Three Phase Energy Meter',
                        price: 110,
                        available: false
                    }
                ]
            },
        ],
        evsii: {
            part_no: 'EVSII-CARBON-7-SOCKET',
            title: 'Intelli EVS II 7.4kW Carbon Fibre Edition',
            selected: {
                color: 'CARBON-FIBRE',
                power: '7',
                type: 'UNTETHERED'
            },
            color: [
                {label: 'Carbon Fibre', value: 'CARBON-FIBRE', texture: 'carbon-fibre.png', available: true},
                {label: 'Black', value: 'BLACK', color: '#000000', available: false},
                {label: 'White', value: 'WHITE', color: '#ffffff', available: false}
            ],
            power: [
                {label: '7.4 kW', value: '7', available: true},
                {label: '22 kW', value: '22', available: false},
            ],
            types: [
                {label: 'Socketed', value: 'UNTETHERED', available: true},
                {label: 'Tethered', value: 'TETHERED', available: false},
            ],
            checklist: ['OZEV Approved', '2023 Regulation Compliant', 'Wi-Fi, Ethernet, 4G as Standard', 'Solar integration', 'Load balancing up to 32 devices', '3-year warranty', 'Powered by Monta']
        },
        evs: {
            part_no: 'EVS-22-SOCKET',
            title: 'Intelli EVS 22kW Galvanised Steel Edition',
            selected: {
                color: 'BLACK',
                power: '22',
                type: 'UNTETHERED'
            },
            color: [
                {label: 'Black', value: 'BLACK', color: '#000000', available: true},
            ],
            power: [
                {label: '22 kW', value: '22', available: true},
            ],
            types: [
                {label: 'Socketed', value: 'UNTETHERED', available: true},
            ],
            checklist: [
                'OZEV Approved',
                'IP58 Protection',
                'MID certified',
                'Wi-Fi, Ethernet as Standard',
                '3-year warranty',
                'Powered by Monta'
            ]
        }
    }
}

export function getById(id) {
    return getAll()[id]
}