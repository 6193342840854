<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | Intelli EV Installer Portal` : `Intelli EV Installer Portal` }}</template>
  </metainfo>
  <router-view></router-view>

  <!-- Global notification live region, render this permanently at the end of the document -->
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end" id="notification-area">
    </div>
  </div>

</template>
<script>
export default {
  name: 'App'
}
</script>