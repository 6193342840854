import { createApp } from 'vue'
import App from './App.vue'

import './assets/index.css'

import axios from "./utils/api"
import PageLayout from "@/Layout/PageLayout.vue";

import router from "./router"
import VueGtag from "vue-gtag";
import {createPinia} from "pinia";
import {createMetaManager} from "vue-meta";

const vue = createApp(App)
vue.config.globalProperties.$http = axios;

vue.use(createMetaManager())
vue.use(createPinia());
vue.use(VueGtag, {
    config: { id: "G-VFK2SWC7R5" }
})
vue.component('PageLayout', PageLayout);
vue.use(router);
vue.mount('#app')
