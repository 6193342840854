<template>
  <page-layout>
    <template v-slot:content>

      <page-header>
        <template v-slot:icon>
          <svg  class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        </template>
        <template v-slot:title>
          Place an Order
        </template>
      </page-header>



      <div class="bg-white mt-6 rounded">


        <div>
          <div class="pt-8 px-8 sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
            <select v-model="view.selected" id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-intelli-blue focus:ring-intelli-blue">
              <option v-for="(o, k) of view.options" :value="o.id" :key="'option-' + k">{{ o.title }}</option>
            </select>
          </div>
          <div class="hidden sm:block">
            <div class="border-b border-gray-200">
              <nav class="-mb-px flex" aria-label="Tabs">
                <button
                    @click="() => view.selected = b.id"
                    :class="{
                  'border-intelli-blue text-intelli-blue': b.id === view.selected,
                  'hover:border-gray-300 hover:text-gray-700': b.id !== view.selected,
                    }"
                    :key="k" v-for="(b, k) of  view.options" class="border-transparent text-gray-500 w-full border-b-2 py-4 px-1 text-center text-sm font-medium">
                  {{ b.title }}
                </button>


              </nav>
            </div>
          </div>
        </div>

        <div>

          <charger-component v-if="view.selected === 'evsii'" :rrp="649"  sku="EV7SII" uan="731093497636" :price="findPriceById('EVSII-CARBON-7-SOCKET')" product-id="evsii" />
          <charger-component v-if="view.selected === 'evs'" :rrp="1290" sku="EV22S" uan="0731093497650" :price="findPriceById('EVS-22-SOCKET')" product-id="evs" />

          <div  v-if="view.selected === 'accessories'" >
            <div :key="'accessories-' + k" v-for="(a, k) of getAccessories()">
              <h4 class="font-semibold text-lg p-8">{{ a.title }}</h4>

              <div class="divide-y divide-gray-200">
                <div :key="'accessories-' + k" v-for="(a, k) of a.items" class="p-4 px-8 flex items-center justify-center gap-x-6">
                  <div class="flex-grow">

                    <div class="flex w-full h-full">
                      <div class="w-20 h-20">
                        <img  class="w-20 rounded-md"
                              :src="'/images/products/' + a.part_no + '.png'" :alt="a.title" :title="a.title" />
                      </div>
                      <div class="flex-grow">
                        <h4 class="font-bold mb-3 text-md">
                          {{ a.title }}
                        </h4>
                        <h5>
                          {{ toCurrency(a.price) }} <span class="text-xs">excl. VAT</span>
                        </h5>
                      </div>

                    </div>

                  </div>
                  <div class="w-40">
                    <button
                        @click="() => addToBasket(a)"
                        :disabled="!a.available"
                        :class="{
                          'cursor-not-allowed': !a.available,
                          'bg-intelli-green hover:bg-intelli-green-dark': a.available,
                          'bg-gray-300 hover:bg-gray-300': !a.available,
                        }"
                        class="flex gap-x-2 w-full flex-1 items-center justify-center rounded-md border border-transparent text-sm py-2 px-4 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-intelli-green focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full">
                      <span>{{ a.available ? 'Add to Basket' : 'Out of Stock' }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>

      </div>

    </template>
  </page-layout>
</template>

<script>
import PageLayout from "@/Layout/PageLayout";
import PageHeader from "@/components/PageHeader";
import {useMeta} from "vue-meta";
import ChargerComponent from "@/components/Products/ChargerComponent";
import { getById } from "@/utils/product-catalogue";
import {notify} from "@/utils/notification";
import {useAuthStore} from "@/store";

export default {
  name: "CreateNewOrder",
  components: {ChargerComponent, PageHeader, PageLayout},
  setup() {
    const store = useAuthStore();
    useMeta({
      title: 'Create new Order'
    })
    return {
      basePricing: store.basePricing,
      setItemToBasket: store.setItemToBasket
    }
  },
  methods: {
    getAccessories(){
      return getById('accessories')
    },
    findPriceById(id){
      let find = this.basePricing.find((x) => {
        return x.product_id === id;
      });
      return find && find.price ? find.price : null;
    },
    toCurrency(value, locale, currency) {
      locale = locale || 'en-GB';
      currency = currency || 'GBP';
      return new Intl.NumberFormat(locale, { style: 'currency', currency,
        minimumFractionDigits: 2
      }).format(value);
    },
    addToBasket({part_no, title}){
      this.setItemToBasket(part_no);
      setTimeout(() => {
        notify({
          title: 'Added to Basket',
          message: title + ' added to basket'
        });
      }, 10)
    }
  },
  data(){
    return {
      view: {
        selected: 'evsii',
        options: [
          {id: 'evsii', title: 'EVS II'},
          {id: 'evs', title: 'EVS'},
          {id: 'accessories', title: 'Accessories'},
        ]
      }
    }
  }
}
</script>