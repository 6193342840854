<template>
  <page-layout>
    <template v-slot:content>


      <page-header>
        <template v-slot:icon>
          <svg  class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"></path></svg>
        </template>
        <template v-slot:title>
         Training Guides & Videos
        </template>
      </page-header>

      <div class="my-5">
        <h4 class="mb-4 text-lg font-bold">PDF Guides</h4>

        <h4 class="mb-4 font-bold">EVS II</h4>

        <ul class="grid mb-3 grid-cols-2 lg:grid-cols-4 gap-6">
          <li :key="i" v-for="(x, i) of pdf_guides">
            <download-item :name="x.name" :url="x.url">
              <svg xmlns="http://www.w3.org/2000/svg"
                   class="w-10 h-10"
                   viewBox="0 0 24 24"><path d="M6 22v-16h16v7.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-7.362zm18-7.614v-10.386h-20v20h10.189c3.163 0 9.811-7.223 9.811-9.614zm-10 1.614h-5v-1h5v1zm5-4h-10v1h10v-1zm0-3h-10v1h10v-1zm2-7h-19v19h-2v-21h21v2z"/></svg>
            </download-item>
          </li>
        </ul>


        <h4 class="my-4 mt-8 text-lg font-bold">Training Videos</h4>

        <div :key="'items-' + i" v-for="(t,i) of training_videos">
          <h4 class="mb-4 font-bold">{{ t.title }}</h4>

          <div class="border rounded-lg bg-white shadow">
            <div v-for="(v, k) of t.playlist" :key="k" class="flex cursor-pointer hover:bg-gray-100 p-5 border-b border-gray-200">
              <a class="w-full cursor-pointer" target="_blank" :href="v.link">
              <div class="flex w-full">
                <div class="flex-grow">
                  <h4 class="font-bold">{{ v.id }} - {{ v.name }}</h4>
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-green-500">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                  </svg>
                </div>
              </div>
              </a>
            </div>
          </div>

        </div>

      </div>


    </template>
  </page-layout>
</template>

<script>
import PageLayout from "@/Layout/PageLayout";
import PageHeader from "@/components/PageHeader";
import {useMeta} from "vue-meta";
import DownloadItem from "@/components/DownloadItem";

export default {
  name: "TrainingVideos",
  components: {DownloadItem, PageHeader, PageLayout},
  setup(){
    useMeta({
      title: 'Training & Guides'
    })
  },
  data() {
    return {
      pdf_guides: [
        {name: 'Step-by-Step Installer Guide', url: 'https://intelliev.ams3.cdn.digitaloceanspaces.com/documentation/manuals/Carbon%20Edition%20Installers%20Training%20Manual%20-%20April%202023.pdf'},
      ],
      training_videos: [
        {
          title: 'EVS II',
          playlist: [
            {id: '01', name: 'Unboxing', link: 'https://www.youtube.com/watch?v=cWYQ2uyymfI&list=PLfWarCY6l0o2DNXyUul_Ctsp7avAb1qtz'},
            {id: '02',name: 'Removing the Mounting Bracket', link: 'https://www.youtube.com/watch?v=pYSOv_XHmKA&list=PLfWarCY6l0o2DNXyUul_Ctsp7avAb1qtz'},
            {id: '03',name: 'Fitting the Mounting Bracket', link: 'https://www.youtube.com/watch?v=hgMLZjMlzTY&list=PLfWarCY6l0o2DNXyUul_Ctsp7avAb1qtz'},
            {id: '04',name: 'Wiring up the CP and re-installing the covers', link: 'https://www.youtube.com/watch?v=qwL06B0QvL4&list=PLfWarCY6l0o2DNXyUul_Ctsp7avAb1qtz'},
            {id: '05',name: 'Downloading the Charge Point App', link: 'https://www.youtube.com/watch?v=vD9JNfOyYfs&list=PLfWarCY6l0o2DNXyUul_Ctsp7avAb1qtz'},
            {id: '06',name: 'Charge Point App – Basic Settings', link: 'https://www.youtube.com/watch?v=R21HnfnOi04&list=PLfWarCY6l0o2DNXyUul_Ctsp7avAb1qtz&index=9'},
            {id: '07',name: 'Connecting to the Charge Point using the app and Wifi', link: 'https://www.youtube.com/watch?v=-_jCXlwgWz4&list=PLfWarCY6l0o2DNXyUul_Ctsp7avAb1qtz&index=7'},
            {id: '09',name: 'Commissioning the Charge Point to the Installer Mobile', link: 'https://www.youtube.com/watch?v=_vZYisZU_bI&list=PLfWarCY6l0o2DNXyUul_Ctsp7avAb1qtz&index=8'},
            {id: '10',name: 'Test Charging', link: 'https://www.youtube.com/watch?v=duoJuBODFGM&list=PLfWarCY6l0o2DNXyUul_Ctsp7avAb1qtz&index=9'},
            {id: '11',name: 'Transferring the Charge Point to the End Customers account', link: 'https://www.youtube.com/watch?v=8GE4aWHjMmY&list=PLfWarCY6l0o2DNXyUul_Ctsp7avAb1qtz&index=10'},
            {id: '13',name: 'What is a Socket Lock Error and what does it mean?', link: 'https://www.youtube.com/watch?v=2iOGNknceAI&list=PLfWarCY6l0o2DNXyUul_Ctsp7avAb1qtz&index=11'},
            {id: '14',name: 'Factory Reset', link: 'https://www.youtube.com/watch?v=7z-HVEAJfZw&list=PLfWarCY6l0o2DNXyUul_Ctsp7avAb1qtz&index=12'},
            {id: '16',name: 'Trouble shooting – loose terminal', link: 'https://www.youtube.com/watch?v=qRlcAt7fotg&list=PLfWarCY6l0o2DNXyUul_Ctsp7avAb1qtz&index=13'},
          ]
        }
      ]
    }
  }
}
</script>

