<template>
  <button type="button"
          :disabled="disabled"
          :class="getColorString"
          class="inline-flex justify-center rounded-md py-3 px-4 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">

    <svg v-if="loading" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>

    <span>
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  name: "ElButton",
  props: {
    disabled: {
      type: Boolean,
      default(){
        return false
      }
    },
    color: {
      type: String,
      default(){
        return 'red'
      }
    },
    loading: {
      type: Boolean,
      default(){
        return false
      }
    }
  },
  computed: {
    getColorString(){
      return 'focus-visible:outline-' + this.color + '-500 bg-' + this.color + '-600 ' + (this.disabled ? 'cursor-not-allowed bg-opacity-40' : "")
    }
  }
}
</script>

<style scoped>

</style>